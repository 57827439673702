
.indx{
    @include media-breakpoint-only(md){
        .container.m520{
            max-width: 520px;
        }
    }
    @include media-breakpoint-up(md){
        padding-top: 0;
    }
    .img-center{
        justify-content: center;
        align-items: flex-end;
        display: flex;
    }
   .indx-banner{
       overflow: hidden;
        .slider-banner,.bgcover{
            @include media-breakpoint-up(md){
                width:100%;
                height: 100vh;
            }
        }
        .slider-banner,.slick-slide,.bgcover{
            @include media-breakpoint-down(sm){
                padding-bottom: 68%;
                height: 0;
            }
        }
        .slickbox:after{
            content:' ';
            width:100%;
            height: 100%;
            @include position(absolute,$top:0,$left:0);
            background-color: rgba(0,0,0,.35);
        }
        .bgpattern{
            width:100%;
            height: 100%;
            @include position(absolute,$top:0px,$left:0);
            z-index: 9;
            img{
                height: 120%;
            }
        }
        .dots-container{
            @include position(absolute,$bottom:30px,$left:50%);
            transform: translateX(-50%);
            z-index: 12;
            @include media-breakpoint-down(sm){
                bottom:15px;
            }
        }
        .row{
            @include position(absolute,$top:50%,$left:50%);
            transform: translate(-50%,-50%);
            max-width: $maxConentW;
            width:100%;
            z-index: 10;
            margin:0;
        }
        h3{
            @extend .fen;
            line-height: 1.2;
            @include media-breakpoint-down(sm){
                @include font-color(pxToEm(30),white,700);
                b{
                    display: block;
                }
                margin-bottom: 10px;
            }
            @include media-breakpoint-up(md){
                @include font-color(pxToEm(58),white,700);
            }    
        }
        h3 + div{
            letter-spacing: 1.25px;
            line-height: 1.3;
            @include media-breakpoint-down(sm){
                @include font-color(pxToEm(13),white,700);
            }
            @include media-breakpoint-up(md){
                @include font-color(pxToEm(25),white,700);
            }  
        }
    }
    .news{
        h3 + div{
            width: 100%;
        }
    }
    .indx-news{
        background-color: $blue;
        @include media-breakpoint-down(sm){
            padding: 17px 0;
            // margin:0px 0 50px;
        }
        @include media-breakpoint-up(md){
            padding: 25px 0;
            // margin:0px 0 130px;
        }
        .col-12{
            @include media-breakpoint-up(md){
                display: flex;
                align-items: center;
            }
        }
        h3{
            @extend .fen;
            letter-spacing: 1.75px;
            line-height: 1;
            @include media-breakpoint-down(sm){
                @include font-color(pxToEm(30),white);
                margin-bottom: 13px;
            }
            @include media-breakpoint-up(md){
                @include font-color(pxToEm(35),white);
                margin-right: 60px;
            }
        }
        .btn_arrow{
            .svg{
                fill:$blue;
            }
            line,path{
                stroke: white;
            }
        }
        a.listHover{
            color:white;
            padding:0;
            .mtitle:after{
                background-color: white;
            }
            @include media-breakpoint-up(md){
                &:hover{
                    color:white;                    
                    .btn_arrow{
                        .svg{
                            fill:white;
                        }
                        path{
                            fill:white;
                        }
                        line{
                            stroke: $blue;
                        }
                    }
                }
            }
        }
    }
    .about{
        h1{
            border:none;
        }  
        .sign{
            @include media-breakpoint-down(md){
                margin:30px 0 50px;
            }
            @include media-breakpoint-up(lg){
                @include position(absolute,$bottom:0);
                margin-bottom: 0;
            }
        }
        @include media-breakpoint-down(sm){
            margin-top:50px;
        }
        @include media-breakpoint-up(md){
            margin-top:130px;
        }
    }
    .indx-about{
        @include media-breakpoint-down(sm){
            margin-top: 60px;
            .mdes b{
                display: block;
            }
            .mt30{
                margin-top: 50px;
            }
        }
        @include media-breakpoint-up(md){            
            margin-top: 130px;            
            .mt30{
                margin-top: 30px;
            }
        }
        .m_article{
            margin-bottom: 20px;
        }
        .mflex-4{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-left: -$maxConentPding;
            margin-right: -$maxConentPding;
            text-align: center;
            > div{
                padding:0 $maxConentPding;
                @include media-breakpoint-down(md){ 
                    width:50%;   
                }
                @include media-breakpoint-up(lg){
                    width:25%;
                }
            }
            .img-center{
                @include media-breakpoint-down(sm){ 
                    width:100%;   
                    height: 100px;
                    margin:50px auto 10px;
                    img{
                        max-height: 100%;
                    }
                }
                @include media-breakpoint-up(md){
                    margin:35px 0 25px;
                    height: 173px;
                }
            }
        }
    }
    .indx-lcoaction{
        margin:0 auto;
        position: relative;
        cursor: pointer;
        image-rendering: -moz-crisp-edges;  
        image-rendering: -o-crisp-edges;     
        image-rendering: -webkit-optimize-contrast; 
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; 
        .cursor-box{
            width: 80%;
            font-size: pxToEm(12);
            color: #969696;
            @include position(absolute,$bottom:-5px,$left:50%);
            transform: translateX(-50%);
            img{
                width: 70px;
                margin-right: 16px;
            }
        }
        &:before,&:after{
            content:"";
            width:10%;
            height: 100%;
            @include position(absolute,$top:0);
            z-index: 9;
        }
        &::before{
            background: white;
            background: linear-gradient(to right, rgba(white,1) 50%,rgba(255,255,255,0) 100%);
            left:0px;
          }
        &::after{
            background: white;
            background: linear-gradient(to left, rgba(white,1) 50%,rgba(255,255,255,0) 100%);
            right:0px;
        }
        .m_article{
            margin-bottom: 20px;
        }
        .tab{
            font-size: pxToEm(16);
            line-height: 1.6;
            li{
                border-bottom: 2px solid rgba($pink,0);
                display: inline-block;
                padding:0 20px;
                cursor: pointer;
                transition: all 0.6s;
            }
            li.active{
                border-bottom-color: $pink;
                color:$pink;
            }
        }     
        .mCSB_scrollTools .mCSB_draggerContainer{
            opacity: 0 !important;
        }     
        .img-content{
            max-width: 1200px;
            margin:0 auto;
        }
        .tab-container{  
            image-rendering: -moz-crisp-edges;  
            image-rendering: -o-crisp-edges;     
            image-rendering: -webkit-optimize-contrast; 
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor; 
            .img1{
                width:640px;
                margin: 0 auto;
            }
            .img2{
                width: 1200px;
                margin: 0 auto;
            }
        }
        @include media-breakpoint-down(sm){
            margin-top: 60px;     
            .tab{
                margin-bottom: 24px;
            }  
            
        }
        @include media-breakpoint-up(md){     
            margin-top: 130px;
            .cursor-box{
                display: none;
            }
            .tab{
                margin-bottom: 36px;
            } 
            .tab li:hover{
                border-bottom-color: $pink;
                color:$pink;
            }  
        }
    }
    .indx-service{
        .m_article{
            @include media-breakpoint-down(sm){
                margin-top: 60px;
                margin-bottom: 70px;
            }
            @include media-breakpoint-up(md){
                margin-top: 130px;
                margin-bottom: 70px;
            }
        }
        .box{
            position: relative;
            @include media-breakpoint-down(md){
                padding-top: 80px;
                margin-bottom: 100px;
                &:last-child{
                    margin-bottom: 60px;
                }
            }
            @include media-breakpoint-up(lg){
                display: flex;
                margin-bottom: 160px;
            }
            .parallax-layer{
                transform: translate(0,0);
            }
            &:nth-child(odd){
                @include media-breakpoint-down(md){
                    .mnum{
                        @include position(absolute,$left:0,$top:-50px);
                    }
                    .bggray{
                        @include position(absolute,$right:-15px,$top:0px);
                    }
                }
                @include media-breakpoint-up(lg){
                    .mnum{
                        @include position(absolute,$left:0,$top:21px);
                    }
                    .bggray{
                        @include position(absolute,$right:100px,$top:0px);
                    }
                    .mimg{
                        @include position(absolute,$right:0,$bottom:65px);
                    }
                }
            }
            &:nth-child(even){
                @include media-breakpoint-down(md){
                    .mnum{
                        @include position(absolute,$right:0,$top:-50px);
                    }
                    .bggray{
                        @include position(absolute,$left:-15px,$top:0px);
                    }
                }
                @include media-breakpoint-up(lg){
                    justify-content: flex-end;
                    .mnum{
                        @include position(absolute,$right:0,$top:21px);
                    }
                    .bggray{
                        @include position(absolute,$left:100px,$top:0px);
                    }
                    .mimg{
                        @include position(absolute,$left:0,$bottom:65px);
                    }
                }
            }
        }
        .mnum{
            @extend .fen;
            line-height: 0.7;
            height: 110px;
            // transform: rotate(90deg) translateY(35px);
            writing-mode: vertical-lr;
            text-orientation: mixed;
            display: inline-block;   
            @include media-breakpoint-down(md){
                @include font-color(pxToEm(80),$pink,300);
            }  
            @include media-breakpoint-up(lg){
                @include font-color(pxToEm(150),$pink,300);
            }       
        }
        .bggray{
            background-color: #e8e8e8;
            z-index: 1;
            @include media-breakpoint-down(md){
                width:260px;
                height: 170px;
            } 
            @include media-breakpoint-up(lg){
                width: 665px;
                height: 440px;  
            }
        }
        .mimg{
            position: relative;
            z-index: 5;
        }
        .info{
            background-color: $blue;
            color:white;
            max-width: 530px;
            position: relative;
            z-index: 10;
            line-height: 1.88;
            @include media-breakpoint-down(md){
                padding: 20px;
                min-height: 210px;
                margin-top: -1px;
            }
            @include media-breakpoint-up(lg){
                margin-top: 290px;                
                padding: 35px 40px 80px 35px;
                min-height: 270px;
            }
            .mtitle{
                line-height: 1;
                letter-spacing: 1.2px;
                margin-bottom: 14px;
                @include media-breakpoint-down(sm){
                    font-size: pxToEm(18);
                }
                @include media-breakpoint-up(md){
                    font-size: pxToEm(24);
                }

            }
            .text-right{
                @include media-breakpoint-down(sm){   
                    margin-top: 15px;
                }
            }
            .btn_arrow{
                color:white;
                .svg{
                    path{
                        fill:$blue;
                        stroke: white;
                    }
                    line{
                        stroke:white;
                    }
                }
                @include media-breakpoint-down(sm){
                    // float: right;
                    // margin-top: 15px;
                    font-size: pxToEm(14);
                    .svg{
                        margin-left: 10px;
                    }
                }
                @include media-breakpoint-up(md){
                    @include position(absolute,$right:40px,$bottom:35px);
                    &:hover{
                        path{
                            fill:white;
                        }
                        line{
                            stroke:$blue;
                        }
                    }
                }
            }
        }
        @include media-breakpoint-down(sm){
            .bg1,.bg2{
                display: none;
            }
        }
        @include media-breakpoint-up(md){            
            .bg1{
                @include position(absolute,$top:250px,$left:-5%);
                z-index: -1;
            }
            .bg2{
                @include position(absolute,$bottom:500px,$right:-10%);
                z-index: -1;
            }
        }
    }
    .indx-team{
        @include media-breakpoint-down(md){
            margin-bottom: 120px;
        }
        @include media-breakpoint-up(lg){
            margin-bottom: 150px;
        }
        .zindx-1{
            z-index: 3;
            position: relative;
        }
        .m_article{
            @include media-breakpoint-down(sm){
                margin-bottom: 30px;
            }
            @include media-breakpoint-up(md){
                margin-bottom: 50px;
            }
        }
        .bggray:before{
            content:' ';
            background-color: #e8e8e8;
            @include media-breakpoint-down(sm){
                width:calc(100% - 50px);
                height: calc(100% - 40px); 
                @include position(absolute,$left:25px,$bottom:-90px);
            }
            @include media-breakpoint-up(md){
                width:100%;
                height: calc(100% - 83px); 
                @include position(absolute,$left:0,$bottom:-115px);
            }
        }
        .mflex-3{
            > div{
                background-color: white;
            }
            @include media-breakpoint-down(sm){
                > div{
                    margin-bottom: 50px;
                }
            }
            @include media-breakpoint-up(md){
                display: flex;
                justify-content: space-between;
                margin-left: -$maxConentPding;
                margin-right: -$maxConentPding;
                > div{
                    width:27%;
                }
            }
            @include media-breakpoint-only(md){
                > div{
                    width:30%;
                }
            }
        }
        .fbold{
            font-weight: 700;
        }
        .bgcover{
            padding-bottom: 120.37%;
        }
        .mtxt{
            @include media-breakpoint-down(md){
                padding:16px;
            }
            @include media-breakpoint-up(lg){
                padding:16px 24px ;
            }
        }
        .mname{
            letter-spacing: 1px;
            font-size: pxToEm(20);
        }
        .jobtitle{
            margin:4px 0 12px;
        }
        .btn_radi{
            @include media-breakpoint-up(md){
                margin-top: 30px;
            }
        }
    }
    .indx-partner{
        .m_article{
            margin-bottom: 60px;
        }
        .img-center{
            img{
                max-height: 100%;
            }
            @include media-breakpoint-down(sm){
                height: 110px;
            }
            @include media-breakpoint-up(md){
                height: 125px;
            }
        }
        h3{
            font-size: 20px;
            font-weight: 700;
            @include media-breakpoint-up(md){
                margin-top: 20px;
            }
        }
        .slickprev,.slicknext{
            display: block;
            width:18px;
            height: 35px;
            position: absolute;
            @include position(absolute,$top:50%);
            transform: translateY(-50%);
            
            polyline{
                fill:none;
                stroke:#B9B9B9;
                stroke-linecap:round;
                stroke-linejoin:round;
                stroke-miterlimit:10;
            }
            &:hover{
                polyline{
                    stroke: $blue;
                }
            }
        }
        .slickprev{
            @include media-breakpoint-down(lg){
                left:15px;
            }
            @include media-breakpoint-up(xl){
                left:-5%;
            }
        }
        .slicknext{
            @include media-breakpoint-down(lg){
                right:15px;
            }
            @include media-breakpoint-up(xl){
                right:-5%;
            }
        }
    }
    .indx-contact{
        @include media-breakpoint-down(sm){
            margin-top: 60px;
        }
        @include media-breakpoint-up(md){
            margin-top: 120px;
        }
        .bgpink{
            background-color: $pink;
        }
        .mflex-2{
            @include media-breakpoint-down(md){
                .mflex-2{
                    display: flex;
                    > div{
                        flex:0  0 50%;
                        max-width: 50%;
                        position: relative;
                    }
                }
            }
            @include media-breakpoint-up(lg){
                display: flex;
                > div{
                    flex:0  0 50%;
                    max-width: 50%;
                }
            }
        }
        .info{
            color:white;
            @include media-breakpoint-down(sm){
                padding:20px;
            }
            @include media-breakpoint-up(md){
                padding: 25px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1200px) {
                padding:25px 20px;
            }
            .m_article h5,.m_article h4{
                color:white;
            }
            .mflex-2{
                @include media-breakpoint-down(md){
                    margin-top: 50px;
                }
                @include media-breakpoint-up(lg){
                    margin-top: 35px;
                }
            }
            .mh{
                min-height: 4.5em;
            }
            .bdr{
                border-left:1px solid white;
                padding-left: 5%;
                @include media-breakpoint-down(sm){
                    min-height: 135px;
                }
                @include media-breakpoint-up(md){
                    b{
                        display: block;
                    }
                }
            }
            .text-right-sm{
                @include media-breakpoint-down(sm){
                    text-align: right;
                    @include position(absolute,$bottom:0,$right:0);
                    &.pd20{
                        padding-right: 20px;
                    }
                }
            }
            .btn_arrow{
                color: white;
                margin-top:20px;
                display: inline-block;
                position: relative;
                padding-right: 45px;
                .svg{
                    @include position(absolute,$right:0,$top:50%);
                    transform: translateY(-50%);
                    path{
                        stroke: white;
                        fill:$pink;
                    }
                    line{
                        stroke: white;
                    }
                }
                @include media-breakpoint-down(sm){
                    font-size: pxToEm(12);
                    text-align: right;
                    b{
                        display: block;
                    }
                    .lh36{
                        line-height: 36px;
                    }
                }
                @include media-breakpoint-up(md){
                    &:hover{
                        .svg{
                            path{
                                fill:white;
                            }
                            line{
                                stroke: $pink;
                            }
                        }
                    }
                }
            }
        }
        .mimg{
            @include media-breakpoint-down(sm){
                .bgcover{
                    padding-bottom: 86%;
                }
            }
            @include media-breakpoint-only(md){
                .bgcover{
                    padding-bottom: 55%;
                }
            }
            @include media-breakpoint-up(lg){
                height: 100%;
                width:100%;
                @include position(absolute,$top:0,$right:0);
                .bgcover{
                    height: 100%;
                }
            }
        }
    }
}