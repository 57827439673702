.recru{
    .btn_arrow{
        .svg{
            fill:none;
            path{
                fill:$pink;
                stroke: $pink;
            }
            line{
                stroke:white;
            }
        }
        &:hover{
            @include media-breakpoint-up(md){  
                .svg{
                    path{
                        fill:white;
                    }
                    line{
                        stroke:$pink;
                    }
                }
            }
        }
        @include media-breakpoint-down(sm){
            margin-bottom: 20px;
            display: inline-block;
            @include font-color(pxToEm(16),$pink);
        }
        @include media-breakpoint-up(md){
            @include font-color(pxToEm(14),$pink);
            @include position(absolute,$right:0,$top:0);
        }
    }
    .arrow{
        @include position(absolute,$top:50%,$right:5px);
        transform: translateY(-50%);
        width:20px;
        height: 20px;
        i{
            display: block;
            width:100%;
            height: 100%;
            background: url(../images/h_bnt.png) no-repeat center / 20px;
            transition: all 0.6s;
        }
    }
    .active{
        .listHover{
            color:$blue;
            .mtitle:after{
                background-color: $blue;
            }
        }
        .arrow i{
            transform: rotate(-180deg)
        }
    }
    .listbox{
        border-bottom: 1px solid #878686;        
        .editor_Box{
            display: none;
            position: relative;
        }
        .editor_inner{
            margin-bottom: 20px;
            @include media-breakpoint-up(md){
                padding-right: 120px;
            }
        }
    }
    .listHover{
        cursor: pointer;
    }
    .listHover:hover{
        @include media-breakpoint-up(md){  
            color:$blue;
            .mtitle:after{
                background-color: $blue;
            }
        }
    }
}