//overwirte lightcase
#lightcase-case{
    padding:0 15px;
    iframe{
        max-width:calc(100vw - 30px) !important;
    }
}
#lightcase-overlay{
    background-color: rgba($color: #000000, $alpha: 0.8);
}
a[class*="lightcase-icon-"].lightcase-icon-close{
    // background: url(../svg/lighcase-close.svg) no-repeat center;    
    @include media-breakpoint-down(xs){
        width:20px;
        height: 20px;
        background-size: 20px;
    }
    @include media-breakpoint-up(sm){
        width:34px;
        height: 34px;
        background-size: 34px;
    }
    &:before{
        display: none;
    }
}
.hiddendiv{
    display: none;
}
//slick next prev btn and dots
.slickbox{
    position: relative;
    .svg{
        polyline{
            fill:none;
            stroke:#B9B9B9;
            stroke-linecap:round;
            stroke-linejoin:round;
            stroke-miterlimit:10;
            transition: all 0.6s;
        }
        @include media-breakpoint-down(sm){
            width:15px;
        }
        @include media-breakpoint-up(md){
            width:18px;
        }
    }
    .slickprev,.slicknext{
        display: block;
        @include position(absolute,$top:50%);
        transform: translateY(-50%);
        &:after{
            content:' ';
            width:100%;
            height:100%;
            @include position(absolute,$top:0,$left:0);
            opacity: 0;
            transition: all 0.6s;
        }
        .svg{
            position: relative;
            z-index: 2;
        }
    }
    .slickprev{
        @include media-breakpoint-down(xs){            
            left:-15px;
        }
        @include media-breakpoint-up(sm){
            left:-30px;
        }
        @include media-breakpoint-up(lg){
            width:56px;
            height: 59px;
            padding:11px 17px;            
            left:-65px;
        }
    }
    .slicknext{
        @include media-breakpoint-down(xs){
            right:-15px;
        }
        @include media-breakpoint-up(sm){   
            right:-30px;
        }
        @include media-breakpoint-up(lg){    
            width:58px;
            height: 58px;
            padding:11px 20px;        
            right:-65px;
        }
    }
    @include media-breakpoint-up(lg){
        a:hover{
            .svg polyline{
                stroke:$orange;
            }
        }
    }
    //overwirte dots
    .slick-dots{
        display: flex;
        position: relative;
        margin-top:0;
    }
    .slick-dots li{
        width:15px;
        height: 15px !important;
        @include media-breakpoint-down(xs){
            margin: 0 10px;
            transform: scale(0.8);
        }
        @include media-breakpoint-up(md){
            margin: 0 15px;
        }
    }
    .slick-dots li button{
        width:15px;
        height:15px;
        padding: 0;
    }
    .slick-dots li button::before{
        width:15px;
        height: 15px;
        left:0;
        right:0;
        background: white;
        border:1px solid white;
    }
    .slick-dots li.slick-active button::before{
        background: $blue;
        // transform-origin: center;
        // @include media-breakpoint-down(sm){
        //   transform: scale(1.2);
        // }
        // @include media-breakpoint-up(md){
        //   transform: scale(1.5);
        // }
    }
  }