.fixtxt{
    line-height: 1.88;
    letter-spacing: 0.8px;
    .text-justify{
        @include media-breakpoint-down(sm){
            text-align: initial;
        }
    }
    .mtitle{
        letter-spacing: 1.2px;
        line-height: 1;
        margin-bottom: 40px;
        &:before{
            content:' ';
            display: inline-block;
            width:4px;
            height: 1em;
            background-color: $pink;
            margin-right: 14px;
            vertical-align: -3px;
        }
        @include media-breakpoint-down(sm){
            @include font-color(pxToEm(18),$blue,700);}
        @include media-breakpoint-up(md){
            @include font-color(pxToEm(24),$blue,700);
        }
    }
    .mh100{
        @include media-breakpoint-down(sm){
            height: 70px;
        }
        @include media-breakpoint-up(md){
            height: 100px;
        }
    }
    
    h3{
        line-height: 1;
        text-align: center;
        @include media-breakpoint-down(sm){
            @include font-color(pxToEm(18),black,700);
        }
        @include media-breakpoint-up(md){            
            @include font-color(pxToEm(20),black,700);
        }
    }
    .img-center{
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .bggray{
        background-color: #f2f2f2;
    }
}
.about{
    h1{
        display: inline-block;
        line-height: 1.35;
        letter-spacing: 0px;
        border-bottom: 1px solid $blue;
        @include media-breakpoint-down(sm){
            @include font-color(pxToEm(28),black,700);
        }
        @include media-breakpoint-up(md){
            @include font-color(pxToEm(38),black,700);
        }
    }
    h1 + div{
        line-height: 1.46;
        letter-spacing: 1.2px;
        @include media-breakpoint-down(sm){
            @include font-color(pxToEm(22),black,700);
            margin:30px 0;
        }
        @include media-breakpoint-up(md){
            @include font-color(pxToEm(24),black,700);
            margin:30px 0 40px;
        }
    }
    .sign{
        line-height: 36px;
        font-weight: 700;
        margin-bottom: 60px;
        &:before{
            content:' ';
            display: inline-block;
            width:42px;
            height: 1px;
            margin-right: 10px;
            background-color: black;
            vertical-align: 4px;
        }
        img{
            margin-left: 10px;
            @include media-breakpoint-down(sm){
                width:85px;
                vertical-align: -22px;
            }
            @include media-breakpoint-up(md){
                width:102px;
            }
        }
    }
    .col-12.pdl0{
        @include media-breakpoint-up(lg){  
            padding-left: 0;
        }
    }
    .bggray{
        @include media-breakpoint-down(sm){
            padding:12px 20px;
            b{
                display: block;
            }
        }
        @include media-breakpoint-up(md){
            padding:30px;            
        }
    }
    .img-center{
        height: 122px;
    }
    h3{
        @include media-breakpoint-down(sm){
            margin:25px 0 20px;
        }
        @include media-breakpoint-up(md){
            margin:36px 0 30px;
        }
    }
    .mflex-3{
        @include media-breakpoint-down(sm){
            > div{
                margin-bottom: 40px;
            }
        }
        @include media-breakpoint-up(md){
            display: flex;
            justify-content: space-between;
            margin-left: -$maxConentPding;
            margin-right: -$maxConentPding;
            > div{
                width:30%;
                padding:0 $maxConentPding;
            }
        }
    }
}
.service{
    .anc_point{
        width: 100%;
        height: 1px;
        transform: translateY(-140px);
    }
    .imgalign{
        text-align: center;
    }
    .text-justify{
        @include media-breakpoint-down(sm){
            margin-bottom: 20px;
        }
        @include media-breakpoint-up(md){
            margin-bottom: 40px;
        }
    }
    .btn_radi:hover{
        @include media-breakpoint-up(md){
            background-color: rgba(0,0,0,0);
        }
    }
    .bggray{
        margin-top: 60px;
        @include media-breakpoint-down(md){
            padding:30px 20px;
        }
        
        @include media-breakpoint-up(lg){
            display: flex;
            justify-content: center;
            align-items: center;
            padding:30px;
        }
        .issue{
            text-align: center;
            @include media-breakpoint-down(sm){
                @include font-color(pxToEm(18),$black);
            }
            @include media-breakpoint-up(lg){
                flex:0 0 30%;
                max-width: 30%;
                line-height: 1.36;
                letter-spacing: 1.1px;
                @include font-color(pxToEm(22),$black);
            }
            > div{
                @include media-breakpoint-down(md){
                    margin-bottom: 8px;
                    img{
                        width:30px;
                    }
                }
                @include media-breakpoint-up(lg){
                    margin-bottom: 16px;
                }
            }
            @include media-breakpoint-up(lg){
                b{
                    display: block;
                }
            }
        }
        ul{
            @include media-breakpoint-down(md){
                border-top: 1px solid #dcdddd;                
                padding-top: 30px;
                margin-top: 30px;
            }
            @include media-breakpoint-only(md){
                width:80%;
                margin:30px auto 0;
            }
            @include media-breakpoint-up(lg){
                flex:0 0 70%;
                max-width: 70%;
                border-left: 1px solid #dcdddd;
                padding-left: 30px;
            }
            i{
                letter-spacing: 1.2px;
                line-height: 28px;
                margin-right: 5px;
                @include media-breakpoint-down(md){
                    font-size: pxToEm(18);
                }
                @include media-breakpoint-up(lg){
                    font-size: pxToEm(24);
                }
            }
            span{
                letter-spacing: 0.8px;
            }
            li:not(.libtn){
                display: flex;
                align-items: flex-start;
                margin-bottom: 20px;
            }
            li.libtn{
                @include media-breakpoint-down(md){
                    text-align: center;
                }
            }
        }
    }
    .mflex-4{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: -$maxConentPding;
        margin-right: -$maxConentPding;
        > div{
            padding:0 $maxConentPding;
            @include media-breakpoint-down(md){ 
                width:50%;   
            }
            @include media-breakpoint-up(lg){
                width:25%;
            }
        }
        .img-center{
            @include media-breakpoint-down(sm){ 
                width:50%;   
                height: 80px;
                margin:50px auto 20px;
                img{
                    max-height: 100%;
                }
            }
            @include media-breakpoint-only(md){
                margin:50px 0 30px;
                height: 125px;
            }
            @include media-breakpoint-up(lg){
                margin:20px 0 30px;
                height: 125px;
            }
        }
    }
    @include media-breakpoint-down(sm){
        .sm-mb0{
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-only(md){
        .show-sm{
            display: none;
        }
        .show-lg{
            display: block;
        }
    }
    @include media-breakpoint-down(md){
        .sm-mb50{
            margin-bottom: 50px;
        }
    }
    @include media-breakpoint-up(md){
        .lg-mt70{
            margin-top: 70px;
        }
    }
}
.terms{
    header{
        .svg{
            width:23px;
            height: 23px;
            @include media-breakpoint-down(md){
                @include position(absolute,$right:0,$top:50%);
                transform: translateY(-50%);
            }
        }
        path{
            fill:$blue;
            transition: all 0.6s;
        }
        .bar{
            display: none;
        }
        .menu-expanded.menuicon{
            .bar{
                display: block;
            }
            .svg{
                display: none;
            }
        }
        @include media-breakpoint-down(md){
            .navBox{
                padding-top: 20px;
            }
        }
    }
    h5{
        font-family:'Roboto', 'Microsoft JhengHei', "微軟正黑體", sans-serif;
        @include media-breakpoint-down(sm){
            @include font-color(pxToEm(18),$blue,700);
            margin:50px 0 20px;
        }
        @include media-breakpoint-up(md){
            @include font-color(pxToEm(24),$blue,700);
            margin:90px 0 40px;
        }
    }
    .editor_Box{
        margin-bottom: 50px;
    }
    .btn_radi{
        @include media-breakpoint-down(sm){
            margin:0 10px;
            min-width: 130px;
        }
        @include media-breakpoint-up(md){
            margin:0 15px;
        }
    }
}