@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');
$mfont:'Microsoft JhengHei', "微軟正黑體", sans-serif;
$mfont-en:'Roboto', sans-serif;

.fen{
    font-family:'Roboto', sans-serif;
}
//-------------------------文字顏色-------------------------//
$pink:#f16b66;
$brwon:#b58a4c;
$blue:#365a75;
$orange:#ff6e19;
$yel:#fff100;
$green:#2d4e37;
$gray:#eee;
$fontcolor:#413523;

$menu:#878686;
$acolor:#104179;


$maxConentW:1230px;
$maxConentPding:15px;
