header{
    position: fixed;
	top:0;
    left:0;
	z-index: 999;
    width:100%;
    transition: all 1s;  
    box-shadow: 3.1px 3.9px 7.2px 0.8px rgba(14, 6, 10, 0.14);  
    background-color: white;
    //mmenu
    ul.mmenu{   
		@include media-breakpoint-up(lg){
            display: flex;
            justify-content:flex-end;
            align-items: center;
        }  
        a{
            position: relative;
            @include font-color(pxToEm(22),$menu);
            @include media-breakpoint-up(lg){
                font-size: pxToEm(16);
            }
        }
        li.hasmenu{
            @include media-breakpoint-down(md){
                position: relative;
                border-bottom: 1px solid white;
                transition: all 0.6s;
                i{
                    @include position(absolute,$top:25px,$right:20px);
                    width:20px;
                    height: 20px;
                    background: url(../images/h_bnt.png) no-repeat center / 20px;
                    transition: all 0.6s;
                }
                &.active{
                    border-bottom: 1px solid $blue;
                    i{
                        transform: rotate(-180deg);                    
                        
                    }
                    ul.subBox{
                        max-height: 300px;
                    }
                }
            }
            @include media-breakpoint-up(lg){
                > a{
                    &:after{
                        content:' ';
                        display: block;
                        width:100%;
                        height: 25px;
                        @include position(absolute,$bottom:-25px,$left:0);
                    }
                }
            }
        }
		> li{
            > a{
                position: relative;
                text-align:center;	
                letter-spacing: 0.8px;
                position: relative;
            }     
            &.active {
                > a{color:$blue};
            }	
            @include media-breakpoint-down(md){
                text-align: center;
                margin:0px 20px;
                &:not(.hasmenu){
                    border-bottom: 1px solid $blue;
                }
                &:last-child{
                    border-bottom:none;
                }
                > a{
                    padding:20px 0;
                    display: block;
                }
            }
            @include media-breakpoint-up(lg){
                margin:0 25px;
                > a{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                &:hover {
                    > a{color:$blue};
                    ul.subBox{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            @media only screen and (max-width: 1200px) and (min-width: 992px) {
                margin:0 15px;
            }
        }
        
	}//end mmenu
    //subbox
    ul.subBox{	
        display: block;
        li{
            text-align: center;
        }
        a{
            display:inline-block;
            text-align:center;
            line-height: 1;
        }
        a.active{
            color:$blue; 
        }
        @include media-breakpoint-down(md){ 
            border-top: 1px solid $blue;
            overflow: hidden;
            max-height: 0;
            transition: all 0.8s;
            li{
                margin: 0px 0 25px;
            }
            li:first-child{
                margin-top: 25px;
            }
            a{
                font-size: pxToEm(20);
            }
        }
        @include media-breakpoint-up(lg){
            position: absolute;
            top:76px;
            transform: translateX(-67px);
            z-index:10;        
            opacity: 0;
            visibility: hidden;
            background-color: rgba(white,1);
            border-top: 3px solid $blue;
            box-shadow: 3.1px 3.9px 7.2px 0.8px rgba(14, 6, 10, 0.14);
            padding:10px 25px;
            li{
                padding: 10px 0;
            }
            a:hover,a.active{
                color:$blue; 
            }
        }
            
    }//end subbox
    .menuicon{
        position: absolute;
        @include position(absolute, $right:20px, $top:50%);
        transform: translateY(-50%);
		z-index: 10;       
        .mpsrel{
            width:37px;
            height:37px;
        }
        .bar,.bar:after,.bar:before{
            transition: all .25s;
            content: "";
            position: absolute;
            left: 50%;	  
            top:0;
            height: 2px;
            width: 25px;
            background:$blue;
            transform: translateX(-50%);
        }
        .bar{
            margin-top: 18px;
        }
        .bar:after{top: 8px;}
        .bar:before{top: -8px;}	
		
		@include media-breakpoint-up(lg){
			display: none;
		}
    }
    .menu-expanded.menuicon .bar{
        background:rgba(white,0);
        &:before{
            top:0px;
            transform: translateX(-50%) rotate(-45deg);
            background-color: $blue;

        }
        &:after{
            top:0px;
            transform: translateX(-50%) rotate(45deg);
            background-color: $blue;

        }
    }    
    .logo{
        display: block;
        width:180px;
        flex:0 0 180px;
    }
    .lang{
        text-align: center;
        padding:8px 0 0;      
        margin:0 !important;
        transition: all 0.6s;
        position: relative;
        div a{
            @include media-breakpoint-up(lg){
                color: white;
                margin-bottom: 8px;
            }
        }
        span{
            display: none;
            @include media-breakpoint-up(lg){
                width:35px;
                display: block;
                cursor: pointer;
                position: relative;
                z-index: 1;
                .svg{
                    width:23px;
                    height: 23px;
                }
                path{
                    fill:$blue;
                    transition: all 0.6s;
                }
            }
        }
        div{
            @include media-breakpoint-down(md){
                display: inline-flex;
                align-items: center;
            }
            @include media-breakpoint-up(lg){
                @include position(absolute,$top:0,$left:0);
                width:35px;
                height: 0;
                overflow: hidden;
                padding: 40px 0 5px;
                transition: all 0.6s;  
                @include border();
            }
        }            
        @include media-breakpoint-down(md){
            padding:20px 0;
            a{
                @include border();
                width:40px;
                height: 40px;
                line-height: 37px;
                display: block;
                color:$menu;
                margin:0 15px;
            }
            a.active{
                color:$blue;
                border:1px solid $blue;
            }
        }
        @include media-breakpoint-up(lg){
            a{
                display: block;
                margin-bottom: 2px;
                color:white;
            }
            a:after{
                content: ' ';
                display: block;
                background-color: white;
                margin: 0px auto;
                width:1em;
                height: 1px;
                opacity: 0;
                transition: all 0.6s;
                margin-top: -2px;
            }
            a.active:after,a:hover:after{
                opacity: 1;
            }
            &:hover,&.active{
                span{
                    svg path{
                        fill:white;
                    }
                }
                div{
                    background-color:$blue;
                    height: 140px;
                }
            }
        }
    }
    .searchbox{
        @include media-breakpoint-down(md){
            @include position(absolute,$top:20px);
            width:calc(100% - 40px);
            border-bottom: none !important;
        }
        @include media-breakpoint-up(lg){
            margin: 0 15px 0 25px !important;
            width: 260px;
        }
        @media only screen and (max-width: 1200px) and (min-width: 992px) {
            margin: 0 15px 0 0px !important;
        }
        input{
            background-color: rgba(0, 0, 0, 0) !important;
            height: 24px !important;
        }
        .gsc-input-box{
            background-color: #f1f1f1;
            border:1px solid #dcdcdc;
            border-radius: 0px;
            padding:0;
        }
        td#gs_tti50{
            @include media-breakpoint-down(md){
                width:100%;
            }
            @include media-breakpoint-up(lg){
                width:165px;
            }
        }
        .gsc-search-box-tools .gsc-search-box .gsc-input{
            padding-right:12px;
        }
        .cse .gsc-search-button-v2, .gsc-search-button-v2{
            background-color: #cecece !important;
            padding:8px 27px 7px;
            border-color: #cecece !important;
            border-radius: 0 !important;
        }
        .gsc-search-button-v2 svg{
            width:18px;
            height: 18px;
        }
        .gsc-control-cse{
            padding: 0;
        }
        form.gsc-search-box,table.gsc-search-box{
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(md){      
        
        .title-bar{
            padding:20px 15px;
        }
        .navBox{
            display: none;
            width:100%;           
            height:calc(100vh - 72px);
            position: fixed;
			top:72px;
			left:0;
            z-index: 99; 
            background-color: rgba(white,1);            
            overflow-y:scroll; 
            .logo{
                display: none;
            }
            &:before{
                content: ' ';
                width:100%;
                height: 2px;
                @include position(absolute,$top:0,$left:0);
                box-shadow: 0px 1px 4px 2px rgba(14,6,10,.14);
                z-index: 100;
            }
        }
    }
    @include media-breakpoint-down(sm){
        .navBox{
            a.logo{
                width:150px;
            }
        }
        .navBox{
            height:calc(100vh - 50px);
			-webkit-overflow-scrolling: touch;
        }
    }    
    @include media-breakpoint-down(xs){
        .title-bar{
            padding:12px 20px;
        }
    }
    @include media-breakpoint-up(lg){
        padding:0px 50px 0;
        .navBox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 76px;
        }
        .title-bar{
            display: none;
        }
    }
    @media only screen and (max-width: 1200px) and (min-width: 992px) {
        padding:0px 15px;
    }
}
