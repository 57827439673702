.contact{
    ul.info{
        a[href^="tel"], a[href^="tel"]:hover{
            color:black;
        }
        line-height: 1.38;
        letter-spacing: 0.8;
        @include font-color(pxToEm(16),black);
        li{
            display: flex;
            margin-bottom: 24px;
        }
        i{
            display: inline-block;
            width:25px;
            height: 25px;
            @include media-breakpoint-down(sm){ 
                margin-right: 15px;
                transform: scale(0.85);
            }
            @include media-breakpoint-up(md){
                margin-right: 30px;
            }
        }
        i.i_tel{
            // height: 23px;
            background: url(../images/contact/tel.png) no-repeat center / contain;
            vertical-align: -5px;
        }
        i.i_fax{
            // height: 25px;
            background: url(../images/contact/fax.png) no-repeat center / contain;
            vertical-align: -5px;
        }
        i.i_mail{
            // height: 15px;
            background: url(../images/contact/mail.png) no-repeat center / contain;
            vertical-align: -5px;
        }
        i.i_addr{
            // height: 24px;
            background: url(../images/contact/addr.png) no-repeat center / contain;
            vertical-align: -5px;
        }
    }
    .bggray{
        background-color: #f2f2f2;
        @include media-breakpoint-down(sm){
            padding:30px 0;
            margin:70px 0;
        }
        @include media-breakpoint-up(md){
            padding:50px 0;
            margin:90px 0;
        }
    }
    .des{
        line-height: 1.88;
        margin-bottom: 24px;
        @include media-breakpoint-down(sm){
            margin-top: 10px;
        }
    }
    .text-right-lg{
        @include media-breakpoint-up(lg){
            text-align: right;
        }
    }
}