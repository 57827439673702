@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
@import url(plug/jquery.fancybox.min.css);
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* > reset */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

tr,
th,
td {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

.fen, .lang_en .en, .m_article h5, .indx .indx-banner h3, .indx .indx-news h3, .indx .indx-service .mnum {
  font-family: 'Roboto', sans-serif; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
main[role^=main] {
  zoom: 1; }
  main[role^=main]:before, main[role^=main]:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role^=main]:after {
    clear: both; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

p::selection, h1::selection, h2::selection, h3::selection, h4::selection, b::selection, strong::selection, span::selection, li::selection, div::selection, a::selection, img::selection {
  color: #fff;
  background: #000; }

a {
  outline: none; }

a, a:hover {
  text-decoration: none;
  transition: 0.4s;
  color: initial; }

a[href^=tel] {
  color: #413523;
  cursor: default; }
  a[href^=tel]:hover {
    color: #413523; }

*:focus {
  outline: none; }

img {
  vertical-align: middle;
  max-width: 100%; }

ul {
  list-style-type: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*----------------------編輯器------------------------*/
.editor_inner {
  margin: 0 auto;
  font-size: 1rem;
  color: black;
  font-weight: 400;
  line-height: 1.88;
  letter-spacing: 0.8px; }
  .editor_inner ul, .editor_inner ol {
    padding-left: 40px; }
  .editor_inner img {
    max-width: 100%;
    height: auto !important; }
  .editor_inner iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_inner iframe {
        height: auto !important; } }
  .editor_inner h1, .editor_inner h2, .editor_inner h3, .editor_inner h4, .editor_inner h5, .editor_inner h6 {
    margin: inherit; }

/*----------------------頁籤------------------------*/
.page {
  margin: 60px 0 0px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center; }
  .page dt a {
    width: 8px; }
  .page a {
    font-size: 1.25rem;
    color: #878686;
    font-weight: 400;
    display: block;
    line-height: 1; }
  .page dd.active a {
    color: #365a75; }
  .page .svg {
    width: 8px;
    height: 14px;
    fill: none;
    stroke: #878686;
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-miterlimit: 10; }
  @media (max-width: 767.99px) {
    .page {
      justify-content: space-around; } }
  @media (min-width: 768px) {
    .page {
      justify-content: center; }
      .page a {
        margin: 0 15px; }
      .page a:hover {
        color: #365a75; }
        .page a:hover svg {
          stroke: #365a75; }
      .page .ltbn {
        margin-right: 10px; }
      .page .rtbn {
        margin-left: 10px; } }
  .page .nopage {
    opacity: 0;
    z-index: -1; }

/*----------------------go top------------------------*/
#topBtn {
  width: 50px;
  height: 36px;
  text-align: center;
  display: block;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  font-size: 0.75rem;
  color: #f16b66;
  font-weight: 500; }
  #topBtn img {
    width: 35px;
    transition: all 0.6s;
    transform: translateY(-3px); }
  #topBtn.open {
    opacity: 1;
    visibility: visible;
    z-index: 100; }
  #topBtn.change {
    position: absolute; }
    @media (max-width: 575.99px) {
      #topBtn.change {
        bottom: 10px; } }
    @media (min-width: 576px) {
      #topBtn.change {
        bottom: 25px; } }
  @media (max-width: 575.99px) {
    #topBtn {
      position: fixed;
      right: 10px;
      bottom: 80px; } }
  @media (min-width: 576px) {
    #topBtn {
      position: fixed;
      right: 30px;
      bottom: 25px; } }
  @media (min-width: 768px) {
    #topBtn:hover img {
      transform: translateY(-10px); } }

.recru #topBtn.change, .contact #topBtn.change {
  position: absolute; }
  @media (min-width: 576px) {
    .recru #topBtn.change, .contact #topBtn.change {
      bottom: 80px; } }

@media (min-width: 576px) {
  .recru #topBtn, .contact #topBtn {
    position: fixed;
    right: 30px;
    bottom: 80px; } }

/*----------------------top-menu-ul------------------------*/
@media (max-width: 991.99px) {
  .rowbox.top-menu-row {
    margin-left: 0;
    margin-right: 0;
    padding: 0; } }

#top-menu-ul {
  position: relative;
  z-index: 1; }
  @media (max-width: 767.99px) {
    #top-menu-ul {
      margin: 0 auto 30px; } }
  @media (min-width: 768px) {
    #top-menu-ul {
      margin-bottom: 60px; } }
  #top-menu-ul .item_Menu {
    width: calc(100% - 40px);
    margin: 0 auto;
    overflow: hidden; }
  #top-menu-ul .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: center; }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-flex; }
    @media (max-width: 575.99px) {
      #top-menu-ul .item_menu_Box li {
        height: 40px; } }
    @media (min-width: 576px) {
      #top-menu-ul .item_menu_Box li {
        height: 50px; } }
    #top-menu-ul .item_menu_Box li a {
      position: relative;
      background-color: #dddddd;
      text-align: center;
      display: block;
      margin: 0 10px;
      letter-spacing: 0.9px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      border-radius: 25px; }
      @media (max-width: 575.99px) {
        #top-menu-ul .item_menu_Box li a {
          font-size: 1rem;
          color: black;
          font-weight: 400;
          height: 40px;
          line-height: 40px;
          padding: 0 20px; } }
      @media (min-width: 576px) {
        #top-menu-ul .item_menu_Box li a {
          font-size: 1.125rem;
          color: black;
          font-weight: 400;
          height: 50px;
          line-height: 50px;
          padding: 0 30px; } }
    #top-menu-ul .item_menu_Box li.active a {
      color: white;
      background-color: #ff6e19; }
    @media (min-width: 768px) {
      #top-menu-ul .item_menu_Box li a:hover {
        color: white;
        background-color: #ff6e19; } }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 30px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 10; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 0%; }
    @media (max-width: 575.99px) {
      .flex-direction-nav a {
        height: 40px; } }
    @media (min-width: 576px) {
      .flex-direction-nav a {
        height: 50px; } }
  .flex-direction-nav a.flex-next {
    background: url(../svg/slick-next.svg) white no-repeat center/15px; }
    @media (max-width: 575.99px) {
      .flex-direction-nav a.flex-next {
        right: -20px; } }
    @media (min-width: 576px) {
      .flex-direction-nav a.flex-next {
        right: 0px; } }
  .flex-direction-nav a.flex-prev {
    background: url(../svg/slick-prev.svg) white no-repeat center/15px; }
    @media (max-width: 575.99px) {
      .flex-direction-nav a.flex-prev {
        left: -20px; } }
    @media (min-width: 576px) {
      .flex-direction-nav a.flex-prev {
        left: 0px; } }
  @media screen and (max-width: 441px) {
    .flex-direction-nav {
      display: block; } }

.open_flexslider .flex-direction-nav {
  display: block; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.mpsrel {
  position: relative; }

.hide {
  display: none; }

.bgcover {
  width: 100%;
  height: 0;
  background: center / cover; }

.rowbox {
  margin: 0 auto;
  position: relative;
  max-width: 1230px;
  padding: 0 15px; }

@media (max-width: 991.99px) {
  .show-lg {
    display: none; }
  .row.col-md-center {
    justify-content: center; }
  .bgcover[data-src-lg] {
    content: attr(data-src-lg, url); } }

@media (min-width: 992px) {
  .show-sm {
    display: none; }
  .bgcover[data-src-sm] {
    content: attr(data-src-sm, url); } }

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  transition: all 1s;
  box-shadow: 3.1px 3.9px 7.2px 0.8px rgba(14, 6, 10, 0.14);
  background-color: white; }
  @media (min-width: 992px) {
    header ul.mmenu {
      display: flex;
      justify-content: flex-end;
      align-items: center; } }
  header ul.mmenu a {
    position: relative;
    font-size: 1.375rem;
    color: #878686;
    font-weight: 400; }
    @media (min-width: 992px) {
      header ul.mmenu a {
        font-size: 1rem; } }
  @media (max-width: 991.99px) {
    header ul.mmenu li.hasmenu {
      position: relative;
      border-bottom: 1px solid white;
      transition: all 0.6s; }
      header ul.mmenu li.hasmenu i {
        position: absolute;
        top: 25px;
        right: 20px;
        width: 20px;
        height: 20px;
        background: url(../images/h_bnt.png) no-repeat center/20px;
        transition: all 0.6s; }
      header ul.mmenu li.hasmenu.active {
        border-bottom: 1px solid #365a75; }
        header ul.mmenu li.hasmenu.active i {
          transform: rotate(-180deg); }
        header ul.mmenu li.hasmenu.active ul.subBox {
          max-height: 300px; } }
  @media (min-width: 992px) {
    header ul.mmenu li.hasmenu > a:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 25px;
      position: absolute;
      bottom: -25px;
      left: 0; } }
  header ul.mmenu > li > a {
    position: relative;
    text-align: center;
    letter-spacing: 0.8px;
    position: relative; }
  header ul.mmenu > li.active > a {
    color: #365a75; }
  @media (max-width: 991.99px) {
    header ul.mmenu > li {
      text-align: center;
      margin: 0px 20px; }
      header ul.mmenu > li:not(.hasmenu) {
        border-bottom: 1px solid #365a75; }
      header ul.mmenu > li:last-child {
        border-bottom: none; }
      header ul.mmenu > li > a {
        padding: 20px 0;
        display: block; } }
  @media (min-width: 992px) {
    header ul.mmenu > li {
      margin: 0 25px; }
      header ul.mmenu > li > a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center; }
      header ul.mmenu > li:hover > a {
        color: #365a75; }
      header ul.mmenu > li:hover ul.subBox {
        visibility: visible;
        opacity: 1; } }
  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    header ul.mmenu > li {
      margin: 0 15px; } }
  header ul.subBox {
    display: block; }
    header ul.subBox li {
      text-align: center; }
    header ul.subBox a {
      display: inline-block;
      text-align: center;
      line-height: 1; }
    header ul.subBox a.active {
      color: #365a75; }
    @media (max-width: 991.99px) {
      header ul.subBox {
        border-top: 1px solid #365a75;
        overflow: hidden;
        max-height: 0;
        transition: all 0.8s; }
        header ul.subBox li {
          margin: 0px 0 25px; }
        header ul.subBox li:first-child {
          margin-top: 25px; }
        header ul.subBox a {
          font-size: 1.25rem; } }
    @media (min-width: 992px) {
      header ul.subBox {
        position: absolute;
        top: 76px;
        transform: translateX(-67px);
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        background-color: white;
        border-top: 3px solid #365a75;
        box-shadow: 3.1px 3.9px 7.2px 0.8px rgba(14, 6, 10, 0.14);
        padding: 10px 25px; }
        header ul.subBox li {
          padding: 10px 0; }
        header ul.subBox a:hover, header ul.subBox a.active {
          color: #365a75; } }
  header .menuicon {
    position: absolute;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 10; }
    header .menuicon .mpsrel {
      width: 37px;
      height: 37px; }
    header .menuicon .bar, header .menuicon .bar:after, header .menuicon .bar:before {
      transition: all .25s;
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      height: 2px;
      width: 25px;
      background: #365a75;
      transform: translateX(-50%); }
    header .menuicon .bar {
      margin-top: 18px; }
    header .menuicon .bar:after {
      top: 8px; }
    header .menuicon .bar:before {
      top: -8px; }
    @media (min-width: 992px) {
      header .menuicon {
        display: none; } }
  header .menu-expanded.menuicon .bar {
    background: rgba(255, 255, 255, 0); }
    header .menu-expanded.menuicon .bar:before {
      top: 0px;
      transform: translateX(-50%) rotate(-45deg);
      background-color: #365a75; }
    header .menu-expanded.menuicon .bar:after {
      top: 0px;
      transform: translateX(-50%) rotate(45deg);
      background-color: #365a75; }
  header .logo {
    display: block;
    width: 180px;
    flex: 0 0 180px; }
  header .lang {
    text-align: center;
    padding: 8px 0 0;
    margin: 0 !important;
    transition: all 0.6s;
    position: relative; }
    @media (min-width: 992px) {
      header .lang div a {
        color: white;
        margin-bottom: 8px; } }
    header .lang span {
      display: none; }
      @media (min-width: 992px) {
        header .lang span {
          width: 35px;
          display: block;
          cursor: pointer;
          position: relative;
          z-index: 1; }
          header .lang span .svg {
            width: 23px;
            height: 23px; }
          header .lang span path {
            fill: #365a75;
            transition: all 0.6s; } }
    @media (max-width: 991.99px) {
      header .lang div {
        display: inline-flex;
        align-items: center; } }
    @media (min-width: 992px) {
      header .lang div {
        position: absolute;
        top: 0;
        left: 0;
        width: 35px;
        height: 0;
        overflow: hidden;
        padding: 40px 0 5px;
        transition: all 0.6s;
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em; } }
    @media (max-width: 991.99px) {
      header .lang {
        padding: 20px 0; }
        header .lang a {
          -webkit-border-radius: 99em;
          -moz-border-radius: 99em;
          border-radius: 99em;
          width: 40px;
          height: 40px;
          line-height: 37px;
          display: block;
          color: #878686;
          margin: 0 15px; }
        header .lang a.active {
          color: #365a75;
          border: 1px solid #365a75; } }
    @media (min-width: 992px) {
      header .lang a {
        display: block;
        margin-bottom: 2px;
        color: white; }
      header .lang a:after {
        content: ' ';
        display: block;
        background-color: white;
        margin: 0px auto;
        width: 1em;
        height: 1px;
        opacity: 0;
        transition: all 0.6s;
        margin-top: -2px; }
      header .lang a.active:after, header .lang a:hover:after {
        opacity: 1; }
      header .lang:hover span svg path, header .lang.active span svg path {
        fill: white; }
      header .lang:hover div, header .lang.active div {
        background-color: #365a75;
        height: 140px; } }
  @media (max-width: 991.99px) {
    header .searchbox {
      position: absolute;
      top: 20px;
      width: calc(100% - 40px);
      border-bottom: none !important; } }
  @media (min-width: 992px) {
    header .searchbox {
      margin: 0 15px 0 25px !important;
      width: 260px; } }
  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    header .searchbox {
      margin: 0 15px 0 0px !important; } }
  header .searchbox input {
    background-color: rgba(0, 0, 0, 0) !important;
    height: 24px !important; }
  header .searchbox .gsc-input-box {
    background-color: #f1f1f1;
    border: 1px solid #dcdcdc;
    border-radius: 0px;
    padding: 0; }
  @media (max-width: 991.99px) {
    header .searchbox td#gs_tti50 {
      width: 100%; } }
  @media (min-width: 992px) {
    header .searchbox td#gs_tti50 {
      width: 165px; } }
  header .searchbox .gsc-search-box-tools .gsc-search-box .gsc-input {
    padding-right: 12px; }
  header .searchbox .cse .gsc-search-button-v2, header .searchbox .gsc-search-button-v2 {
    background-color: #cecece !important;
    padding: 8px 27px 7px;
    border-color: #cecece !important;
    border-radius: 0 !important; }
  header .searchbox .gsc-search-button-v2 svg {
    width: 18px;
    height: 18px; }
  header .searchbox .gsc-control-cse {
    padding: 0; }
  header .searchbox form.gsc-search-box, header .searchbox table.gsc-search-box {
    margin-bottom: 0; }
  @media (max-width: 991.99px) {
    header .title-bar {
      padding: 20px 15px; }
    header .navBox {
      display: none;
      width: 100%;
      height: calc(100vh - 72px);
      position: fixed;
      top: 72px;
      left: 0;
      z-index: 99;
      background-color: white;
      overflow-y: scroll; }
      header .navBox .logo {
        display: none; }
      header .navBox:before {
        content: ' ';
        width: 100%;
        height: 2px;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 0px 1px 4px 2px rgba(14, 6, 10, 0.14);
        z-index: 100; } }
  @media (max-width: 767.99px) {
    header .navBox a.logo {
      width: 150px; }
    header .navBox {
      height: calc(100vh - 50px);
      -webkit-overflow-scrolling: touch; } }
  @media (max-width: 575.99px) {
    header .title-bar {
      padding: 12px 20px; } }
  @media (min-width: 992px) {
    header {
      padding: 0px 50px 0; }
      header .navBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 76px; }
      header .title-bar {
        display: none; } }
  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    header {
      padding: 0px 15px; } }

footer {
  background-color: #365a75;
  line-height: 1.86;
  letter-spacing: 0.7px;
  font-size: 0.875rem;
  color: white;
  font-weight: 400; }
  @media (max-width: 767.99px) {
    footer {
      padding: 25px 0px 15px; } }
  @media (min-width: 768px) {
    footer {
      padding: 40px 15px; } }
  footer .row {
    max-width: 1230px;
    margin: 0 auto; }
  footer .flogo img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; }
  @media (max-width: 767.99px) {
    footer .flogo {
      width: 150px; } }
  @media (min-width: 768px) {
    footer .flogo {
      width: 180px; } }
  footer .copyright {
    color: #94a5b2; }
    @media (max-width: 767.99px) {
      footer .copyright {
        margin-top: 20px; } }
    @media (min-width: 768px) {
      footer .copyright {
        position: absolute;
        right: 15px;
        bottom: 0px; } }
  footer a[href^="tel"], footer a[href^="tel"]:hover,
  footer a, footer a:hover {
    color: white; }
  footer .info {
    margin-top: 24px; }
    @media (min-width: 768px) {
      footer .info {
        display: inline-flex;
        flex-wrap: wrap; }
        footer .info li:not(:first-child) {
          margin-left: 20px;
          padding-left: 20px;
          position: relative; }
          footer .info li:not(:first-child):before {
            content: ' ';
            display: inline-block;
            width: 1px;
            height: 1em;
            background-color: white;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%); } }
    footer .info .w100 {
      flex: 0 0 100%; }

.lang_tw .en, .lang_tw .cn {
  display: none; }

.lang_cn {
  font-family: "Microsoft Yahei"; }
  .lang_cn .tw, .lang_cn .en {
    display: none; }

.lang_en {
  letter-spacing: 0; }
  .lang_en .tw, .lang_en .cn {
    display: none; }
  .lang_en .text-justify {
    text-align: left; }
  .lang_en .indx .indx-contact .info .mh {
    font-size: 0.9375rem; }
  .lang_en .service .bggray ul span {
    letter-spacing: 0; }
  .lang_en .en {
    letter-spacing: 0; }

/******************************************/
/*		pages
/******************************************/
body {
  font-family: "Microsoft JhengHei", "微軟正黑體", sans-serif;
  font-size: 1rem;
  color: #000;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.8px;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden; }
  body.fade-in {
    opacity: 1;
    transition: opacity  1s ease;
    visibility: visible; }

@media (max-width: 575.99px) {
  main .container, main .col-12 {
    padding-left: 25px;
    padding-right: 25px; }
  main .row {
    margin-right: -25px;
    margin-left: -25px; } }

@media (max-width: 767.99px) {
  main {
    padding-bottom: 50px;
    padding-top: 72px; } }

@media (min-width: 768px) {
  main {
    padding-bottom: 130px;
    padding-top: 76px; }
    main .container {
      max-width: 1200px; } }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  main .row .col-lg-10.offset-lg-1:not(.offset-not) {
    margin: 0 auto;
    flex: 0 0 98%;
    max-width: 98%; } }

.m_article {
  line-height: 1; }
  .m_article h5 {
    color: #365a75; }
    @media (max-width: 767.99px) {
      .m_article h5 {
        font-size: 2rem;
        margin-bottom: 6px; } }
    @media (min-width: 768px) {
      .m_article h5 {
        font-size: 2.8125rem;
        margin-bottom: 12px; } }
  .m_article h4 {
    color: #365a75;
    font-weight: 700;
    letter-spacing: 1.3px; }
    @media (max-width: 767.99px) {
      .m_article h4 {
        font-size: 1.25rem; } }
    @media (min-width: 768px) {
      .m_article h4 {
        font-size: 1.625rem; } }

.pgname {
  position: relative; }
  @media (max-width: 767.99px) {
    .pgname {
      margin-bottom: 60px;
      height: 225px; }
      .pgname .show-l {
        display: none; } }
  @media (min-width: 768px) {
    .pgname {
      margin-bottom: 70px;
      height: 370px; }
      .pgname .show-m {
        display: none; } }
  .pgname .bgcover {
    height: 100%; }
  .pgname .m_article {
    width: 100%;
    max-width: 1230px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media (max-width: 575.99px) {
      .pgname .m_article {
        padding: 0 25px; } }
    @media (min-width: 576px) {
      .pgname .m_article {
        padding: 0 15px; } }
    @media (min-width: 992px) {
      .pgname .m_article {
        top: calc(50% + 38px); }
        .pgname .m_article h5, .pgname .m_article h4 {
          margin-left: 8.33333%; } }
  .pgname .imgalpha {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../images/page/imgalpah.png) no-repeat;
    background-size: contain; }
    @media (max-width: 767.99px) {
      .pgname .imgalpha {
        background-position: -50% 100%; } }
    @media (min-width: 768px) {
      .pgname .imgalpha {
        background-position: left bottom; } }
  .pgname img {
    width: 100%; }

.svg path, .svg line {
  transition: all 0.6s; }

.btn_arrow {
  letter-spacing: 0.7px; }
  .btn_arrow .svg {
    stroke-width: 2;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    width: 32px;
    height: 32px;
    vertical-align: -11px;
    margin-left: 12px; }
  @media (max-width: 767.99px) {
    .btn_arrow i {
      display: none; } }

.btn_radi {
  display: inline-block;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background-color: #f16b66;
  border: 1px solid #f16b66;
  text-align: center; }
  .btn_radi.mgray, .btn_radi.indxbtn {
    background-color: #b9b9b9;
    border: 1px solid #b9b9b9; }
  @media (max-width: 767.99px) {
    .btn_radi {
      padding: 0 35px;
      min-width: 150px;
      line-height: 43px;
      height: 43px;
      font-size: 1.125rem;
      color: white;
      font-weight: 400; }
      .btn_radi:hover {
        color: white; } }
  @media (min-width: 768px) {
    .btn_radi {
      padding: 0 28px;
      min-width: 100px;
      line-height: 33px;
      height: 33px;
      font-size: 0.875rem;
      color: white;
      font-weight: 400; }
      .btn_radi:hover {
        background-color: white;
        color: #f16b66; }
      .btn_radi.mgray:hover {
        background-color: white;
        color: #b9b9b9; }
      .btn_radi.indxbtn:hover {
        background-color: #f16b66;
        border-color: #f16b66;
        color: white; } }

.editor_Box.mb80 {
  margin-bottom: 80px; }

div[data-timeout] {
  opacity: 0;
  transform: translateY(50px); }

div.animated[data-timeout] {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 1s ease-in ,transform 1s; }

#lightcase-case {
  padding: 0 15px; }
  #lightcase-case iframe {
    max-width: calc(100vw - 30px) !important; }

#lightcase-overlay {
  background-color: rgba(0, 0, 0, 0.8); }

@media (max-width: 575.99px) {
  a[class*="lightcase-icon-"].lightcase-icon-close {
    width: 20px;
    height: 20px;
    background-size: 20px; } }

@media (min-width: 576px) {
  a[class*="lightcase-icon-"].lightcase-icon-close {
    width: 34px;
    height: 34px;
    background-size: 34px; } }

a[class*="lightcase-icon-"].lightcase-icon-close:before {
  display: none; }

.hiddendiv {
  display: none; }

.slickbox {
  position: relative; }
  .slickbox .svg polyline {
    fill: none;
    stroke: #B9B9B9;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    transition: all 0.6s; }
  @media (max-width: 767.99px) {
    .slickbox .svg {
      width: 15px; } }
  @media (min-width: 768px) {
    .slickbox .svg {
      width: 18px; } }
  .slickbox .slickprev, .slickbox .slicknext {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .slickbox .slickprev:after, .slickbox .slicknext:after {
      content: ' ';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 0.6s; }
    .slickbox .slickprev .svg, .slickbox .slicknext .svg {
      position: relative;
      z-index: 2; }
  @media (max-width: 575.99px) {
    .slickbox .slickprev {
      left: -15px; } }
  @media (min-width: 576px) {
    .slickbox .slickprev {
      left: -30px; } }
  @media (min-width: 992px) {
    .slickbox .slickprev {
      width: 56px;
      height: 59px;
      padding: 11px 17px;
      left: -65px; } }
  @media (max-width: 575.99px) {
    .slickbox .slicknext {
      right: -15px; } }
  @media (min-width: 576px) {
    .slickbox .slicknext {
      right: -30px; } }
  @media (min-width: 992px) {
    .slickbox .slicknext {
      width: 58px;
      height: 58px;
      padding: 11px 20px;
      right: -65px; } }
  @media (min-width: 992px) {
    .slickbox a:hover .svg polyline {
      stroke: #ff6e19; } }
  .slickbox .slick-dots {
    display: flex;
    position: relative;
    margin-top: 0; }
  .slickbox .slick-dots li {
    width: 15px;
    height: 15px !important; }
    @media (max-width: 575.99px) {
      .slickbox .slick-dots li {
        margin: 0 10px;
        transform: scale(0.8); } }
    @media (min-width: 768px) {
      .slickbox .slick-dots li {
        margin: 0 15px; } }
  .slickbox .slick-dots li button {
    width: 15px;
    height: 15px;
    padding: 0; }
  .slickbox .slick-dots li button::before {
    width: 15px;
    height: 15px;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid white; }
  .slickbox .slick-dots li.slick-active button::before {
    background: #365a75; }

.form_des {
  text-align: center;
  line-height: 1.88; }
  @media (max-width: 767.99px) {
    .form_des {
      margin-bottom: 20px; }
      .form_des b {
        display: block; } }
  @media (min-width: 768px) {
    .form_des {
      margin-bottom: 40px; } }

form {
  overflow: hidden;
  letter-spacing: 1.2px; }
  form input, form select, form textarea {
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    width: 100%;
    padding: 0px 0px 5px;
    background-color: rgba(242, 242, 242, 0);
    border: none;
    border-bottom: solid 1px rgba(135, 134, 134, 0.5);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 1rem;
    color: black;
    font-weight: 400; }
    form input:focus, form select:focus, form textarea:focus {
      outline: none; }
  form input ::-webkit-input-placeholder {
    font-size: 1rem;
    color: #878686;
    font-weight: 400; }
  form input :-moz-placeholder {
    font-size: 1rem;
    color: #878686;
    font-weight: 400; }
  form input ::-moz-placeholder {
    font-size: 1rem;
    color: #878686;
    font-weight: 400; }
  form input :-ms-input-placeholder {
    font-size: 1rem;
    color: #878686;
    font-weight: 400; }
  form input[type="radio"] {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 0; }
  form label {
    line-height: 1;
    letter-spacing: 0.9px;
    margin-bottom: 10px; }
  form label > i {
    line-height: 0.5em;
    vertical-align: 6px;
    margin-left: -7px;
    font-size: 1rem;
    color: #f16b66;
    font-weight: 400; }
  form.form-horizontal .form-group {
    display: flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
  form .form-group {
    margin-bottom: 0; }
    form .form-group.mtop {
      align-items: flex-start; }
    form .form-group.mcenter {
      align-items: center; }
    @media (min-width: 768px) and (max-width: 991.99px) {
      form .form-group {
        margin-bottom: 40px; } }
  form .with-errors {
    flex: 0 0 100%;
    min-height: 14px;
    line-height: 14px;
    letter-spacing: 0.7;
    display: block; }
    @media (max-width: 767.99px) {
      form .with-errors {
        font-size: 1rem;
        color: #f16b66;
        font-weight: 400;
        margin: 7px 0 10px; } }
    @media (min-width: 768px) {
      form .with-errors {
        font-size: 0.875rem;
        color: #f16b66;
        font-weight: 400;
        margin: 3px 0 5px; } }
  form button[type="submit"] {
    outline: none;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    cursor: pointer; }
  @media (min-width: 992px) {
    form .text-r label {
      text-align: right; } }
  @media (min-width: 768px) {
    form input, form select {
      height: 30px; } }
  @media (max-width: 767.99px) {
    form input, form select {
      height: 30px; } }
  form .flex-check_radi input {
    height: 30px; }
  @media (max-width: 575.99px) {
    form .flex-check_radi {
      font-size: 1rem;
      color: black;
      font-weight: 400; }
      form .flex-check_radi label {
        line-height: 30px;
        margin-bottom: 5px; }
      form .flex-check_radi .check_radi {
        line-height: 30px;
        padding: 0 0px 0 30px;
        margin: 0 15px 5px 0px; } }
  @media (min-width: 576px) {
    form .flex-check_radi {
      font-size: 1.125rem;
      color: black;
      font-weight: 400; }
      form .flex-check_radi label {
        line-height: 30px;
        flex: 0 0 120px;
        width: 120px; }
      form .flex-check_radi .g_check_radi {
        display: inline-flex;
        flex-wrap: wrap;
        flex: 0 0 calc(100% - 130px);
        width: calc(100% - 130px); }
      form .flex-check_radi .check_radi {
        line-height: 30px;
        padding: 0 0px 0 50px;
        margin: 0 35px 20px 0px; } }
  form .check_radi {
    width: auto;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    form .check_radi input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0;
      top: 0;
      z-index: 5; }
    form .check_radi .boxmark {
      position: absolute;
      top: 50%;
      left: 0;
      height: 28px;
      width: 28px;
      border: 1px solid #dcdcdc;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em; }
      @media (max-width: 575.99px) {
        form .check_radi .boxmark {
          transform: translateY(-50%) scale(0.7);
          transform-origin: left; } }
      @media (min-width: 576px) {
        form .check_radi .boxmark {
          transform: translateY(-50%); } }
    form .check_radi .boxmark:after {
      content: "";
      position: absolute;
      top: 6px;
      left: 6px;
      display: none;
      width: 14px;
      height: 14px;
      background: #ff6e19;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em; }
    form .check_radi input:checked ~ .boxmark:after {
      display: block; }
    form .check_radi .checkmark {
      position: absolute;
      top: 50%;
      left: 0;
      height: 30px;
      width: 30px;
      border: 1px solid #d2d2d2;
      transform: translateY(-50%); }
    form .check_radi .checkmark:after {
      content: "";
      position: absolute;
      top: 6px;
      left: 4px;
      display: none;
      width: 22px;
      height: 16px;
      background: url("../images/agree_yes.png") no-repeat center/contain; }
    form .check_radi input:checked ~ .checkmark:after {
      display: block; }
  form select::-ms-expand {
    display: none; }
  form select {
    padding: 3px 15px 3px 10px;
    outline: none; }

form .textarea-wrapper {
  height: 194px;
  overflow: hidden;
  border-bottom: solid 1px rgba(135, 134, 134, 0.5);
  padding: 0px 0px 10px; }
  form .textarea-wrapper textarea {
    min-height: 174px;
    overflow: hidden;
    border-bottom: none;
    padding: 0;
    text-rendering: auto;
    -webkit-writing-mode: horizontal-tb !important;
    cursor: text; }

form .agree {
  margin-top: 10px;
  margin-bottom: 12px; }
  form .agree .check_radi {
    padding-left: 40px;
    min-height: 30px; }
  form .agree .with-errors {
    margin-top: 5px; }
  form .agree .with-errors.hide {
    display: none; }

form .editor_Box.recu {
  height: 200px;
  border-bottom: 1px solid rgba(135, 134, 134, 0.5);
  padding-bottom: 20px;
  margin-top: 12px; }

form .mflex-upload {
  display: flex;
  align-items: center; }
  form .mflex-upload label[for="file-upload"] {
    cursor: pointer;
    margin-bottom: 0;
    margin-right: 12px; }
  form .mflex-upload .file_name {
    color: #878686; }
  form .mflex-upload #file-upload {
    position: absolute;
    left: -9999px; }

form .fpink {
  color: #f16b66;
  font-size: 0.875rem; }

form .fgray {
  color: #878686; }

form .submit_recu {
  margin-top: 60px; }

form .submit_contact {
  margin-top: 15px; }

@media (min-width: 768px) {
  form .btn_radi:hover {
    background-color: rgba(0, 0, 0, 0); } }

.mCSB_scrollTools {
  opacity: 1; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #bfbebe;
  width: 1px; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 9px;
  background-color: #cecece !important;
  opacity: 1 !important; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #dcdcdc; }

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  opacity: 1 !important; }

@media (min-width: 768px) and (max-width: 991.99px) {
  .indx .container.m520 {
    max-width: 520px; } }

@media (min-width: 768px) {
  .indx {
    padding-top: 0; } }

.indx .img-center {
  justify-content: center;
  align-items: flex-end;
  display: flex; }

.indx .indx-banner {
  overflow: hidden; }
  @media (min-width: 768px) {
    .indx .indx-banner .slider-banner, .indx .indx-banner .bgcover {
      width: 100%;
      height: 100vh; } }
  @media (max-width: 767.99px) {
    .indx .indx-banner .slider-banner, .indx .indx-banner .slick-slide, .indx .indx-banner .bgcover {
      padding-bottom: 68%;
      height: 0; } }
  .indx .indx-banner .slickbox:after {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35); }
  .indx .indx-banner .bgpattern {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 9; }
    .indx .indx-banner .bgpattern img {
      height: 120%; }
  .indx .indx-banner .dots-container {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 12; }
    @media (max-width: 767.99px) {
      .indx .indx-banner .dots-container {
        bottom: 15px; } }
  .indx .indx-banner .row {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1230px;
    width: 100%;
    z-index: 10;
    margin: 0; }
  .indx .indx-banner h3 {
    line-height: 1.2; }
    @media (max-width: 767.99px) {
      .indx .indx-banner h3 {
        font-size: 1.875rem;
        color: white;
        font-weight: 700;
        margin-bottom: 10px; }
        .indx .indx-banner h3 b {
          display: block; } }
    @media (min-width: 768px) {
      .indx .indx-banner h3 {
        font-size: 3.625rem;
        color: white;
        font-weight: 700; } }
  .indx .indx-banner h3 + div {
    letter-spacing: 1.25px;
    line-height: 1.3; }
    @media (max-width: 767.99px) {
      .indx .indx-banner h3 + div {
        font-size: 0.8125rem;
        color: white;
        font-weight: 700; } }
    @media (min-width: 768px) {
      .indx .indx-banner h3 + div {
        font-size: 1.5625rem;
        color: white;
        font-weight: 700; } }

.indx .news h3 + div {
  width: 100%; }

.indx .indx-news {
  background-color: #365a75; }
  @media (max-width: 767.99px) {
    .indx .indx-news {
      padding: 17px 0; } }
  @media (min-width: 768px) {
    .indx .indx-news {
      padding: 25px 0; } }
  @media (min-width: 768px) {
    .indx .indx-news .col-12 {
      display: flex;
      align-items: center; } }
  .indx .indx-news h3 {
    letter-spacing: 1.75px;
    line-height: 1; }
    @media (max-width: 767.99px) {
      .indx .indx-news h3 {
        font-size: 1.875rem;
        color: white;
        font-weight: 400;
        margin-bottom: 13px; } }
    @media (min-width: 768px) {
      .indx .indx-news h3 {
        font-size: 2.1875rem;
        color: white;
        font-weight: 400;
        margin-right: 60px; } }
  .indx .indx-news .btn_arrow .svg {
    fill: #365a75; }
  .indx .indx-news .btn_arrow line, .indx .indx-news .btn_arrow path {
    stroke: white; }
  .indx .indx-news a.listHover {
    color: white;
    padding: 0; }
    .indx .indx-news a.listHover .mtitle:after {
      background-color: white; }
    @media (min-width: 768px) {
      .indx .indx-news a.listHover:hover {
        color: white; }
        .indx .indx-news a.listHover:hover .btn_arrow .svg {
          fill: white; }
        .indx .indx-news a.listHover:hover .btn_arrow path {
          fill: white; }
        .indx .indx-news a.listHover:hover .btn_arrow line {
          stroke: #365a75; } }

.indx .about h1 {
  border: none; }

@media (max-width: 991.99px) {
  .indx .about .sign {
    margin: 30px 0 50px; } }

@media (min-width: 992px) {
  .indx .about .sign {
    position: absolute;
    bottom: 0;
    margin-bottom: 0; } }

@media (max-width: 767.99px) {
  .indx .about {
    margin-top: 50px; } }

@media (min-width: 768px) {
  .indx .about {
    margin-top: 130px; } }

@media (max-width: 767.99px) {
  .indx .indx-about {
    margin-top: 60px; }
    .indx .indx-about .mdes b {
      display: block; }
    .indx .indx-about .mt30 {
      margin-top: 50px; } }

@media (min-width: 768px) {
  .indx .indx-about {
    margin-top: 130px; }
    .indx .indx-about .mt30 {
      margin-top: 30px; } }

.indx .indx-about .m_article {
  margin-bottom: 20px; }

.indx .indx-about .mflex-4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center; }
  .indx .indx-about .mflex-4 > div {
    padding: 0 15px; }
    @media (max-width: 991.99px) {
      .indx .indx-about .mflex-4 > div {
        width: 50%; } }
    @media (min-width: 992px) {
      .indx .indx-about .mflex-4 > div {
        width: 25%; } }
  @media (max-width: 767.99px) {
    .indx .indx-about .mflex-4 .img-center {
      width: 100%;
      height: 100px;
      margin: 50px auto 10px; }
      .indx .indx-about .mflex-4 .img-center img {
        max-height: 100%; } }
  @media (min-width: 768px) {
    .indx .indx-about .mflex-4 .img-center {
      margin: 35px 0 25px;
      height: 173px; } }

.indx .indx-lcoaction {
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; }
  .indx .indx-lcoaction .cursor-box {
    width: 80%;
    font-size: 0.75rem;
    color: #969696;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%); }
    .indx .indx-lcoaction .cursor-box img {
      width: 70px;
      margin-right: 16px; }
  .indx .indx-lcoaction:before, .indx .indx-lcoaction:after {
    content: "";
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 9; }
  .indx .indx-lcoaction::before {
    background: white;
    background: linear-gradient(to right, white 50%, rgba(255, 255, 255, 0) 100%);
    left: 0px; }
  .indx .indx-lcoaction::after {
    background: white;
    background: linear-gradient(to left, white 50%, rgba(255, 255, 255, 0) 100%);
    right: 0px; }
  .indx .indx-lcoaction .m_article {
    margin-bottom: 20px; }
  .indx .indx-lcoaction .tab {
    font-size: 1rem;
    line-height: 1.6; }
    .indx .indx-lcoaction .tab li {
      border-bottom: 2px solid rgba(241, 107, 102, 0);
      display: inline-block;
      padding: 0 20px;
      cursor: pointer;
      transition: all 0.6s; }
    .indx .indx-lcoaction .tab li.active {
      border-bottom-color: #f16b66;
      color: #f16b66; }
  .indx .indx-lcoaction .mCSB_scrollTools .mCSB_draggerContainer {
    opacity: 0 !important; }
  .indx .indx-lcoaction .img-content {
    max-width: 1200px;
    margin: 0 auto; }
  .indx .indx-lcoaction .tab-container {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; }
    .indx .indx-lcoaction .tab-container .img1 {
      width: 640px;
      margin: 0 auto; }
    .indx .indx-lcoaction .tab-container .img2 {
      width: 1200px;
      margin: 0 auto; }
  @media (max-width: 767.99px) {
    .indx .indx-lcoaction {
      margin-top: 60px; }
      .indx .indx-lcoaction .tab {
        margin-bottom: 24px; } }
  @media (min-width: 768px) {
    .indx .indx-lcoaction {
      margin-top: 130px; }
      .indx .indx-lcoaction .cursor-box {
        display: none; }
      .indx .indx-lcoaction .tab {
        margin-bottom: 36px; }
      .indx .indx-lcoaction .tab li:hover {
        border-bottom-color: #f16b66;
        color: #f16b66; } }

@media (max-width: 767.99px) {
  .indx .indx-service .m_article {
    margin-top: 60px;
    margin-bottom: 70px; } }

@media (min-width: 768px) {
  .indx .indx-service .m_article {
    margin-top: 130px;
    margin-bottom: 70px; } }

.indx .indx-service .box {
  position: relative; }
  @media (max-width: 991.99px) {
    .indx .indx-service .box {
      padding-top: 80px;
      margin-bottom: 100px; }
      .indx .indx-service .box:last-child {
        margin-bottom: 60px; } }
  @media (min-width: 992px) {
    .indx .indx-service .box {
      display: flex;
      margin-bottom: 160px; } }
  .indx .indx-service .box .parallax-layer {
    transform: translate(0, 0); }
  @media (max-width: 991.99px) {
    .indx .indx-service .box:nth-child(odd) .mnum {
      position: absolute;
      top: -50px;
      left: 0; }
    .indx .indx-service .box:nth-child(odd) .bggray {
      position: absolute;
      top: 0px;
      right: -15px; } }
  @media (min-width: 992px) {
    .indx .indx-service .box:nth-child(odd) .mnum {
      position: absolute;
      top: 21px;
      left: 0; }
    .indx .indx-service .box:nth-child(odd) .bggray {
      position: absolute;
      top: 0px;
      right: 100px; }
    .indx .indx-service .box:nth-child(odd) .mimg {
      position: absolute;
      right: 0;
      bottom: 65px; } }
  @media (max-width: 991.99px) {
    .indx .indx-service .box:nth-child(even) .mnum {
      position: absolute;
      top: -50px;
      right: 0; }
    .indx .indx-service .box:nth-child(even) .bggray {
      position: absolute;
      top: 0px;
      left: -15px; } }
  @media (min-width: 992px) {
    .indx .indx-service .box:nth-child(even) {
      justify-content: flex-end; }
      .indx .indx-service .box:nth-child(even) .mnum {
        position: absolute;
        top: 21px;
        right: 0; }
      .indx .indx-service .box:nth-child(even) .bggray {
        position: absolute;
        top: 0px;
        left: 100px; }
      .indx .indx-service .box:nth-child(even) .mimg {
        position: absolute;
        bottom: 65px;
        left: 0; } }

.indx .indx-service .mnum {
  line-height: 0.7;
  height: 110px;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  display: inline-block; }
  @media (max-width: 991.99px) {
    .indx .indx-service .mnum {
      font-size: 5rem;
      color: #f16b66;
      font-weight: 300; } }
  @media (min-width: 992px) {
    .indx .indx-service .mnum {
      font-size: 9.375rem;
      color: #f16b66;
      font-weight: 300; } }

.indx .indx-service .bggray {
  background-color: #e8e8e8;
  z-index: 1; }
  @media (max-width: 991.99px) {
    .indx .indx-service .bggray {
      width: 260px;
      height: 170px; } }
  @media (min-width: 992px) {
    .indx .indx-service .bggray {
      width: 665px;
      height: 440px; } }

.indx .indx-service .mimg {
  position: relative;
  z-index: 5; }

.indx .indx-service .info {
  background-color: #365a75;
  color: white;
  max-width: 530px;
  position: relative;
  z-index: 10;
  line-height: 1.88; }
  @media (max-width: 991.99px) {
    .indx .indx-service .info {
      padding: 20px;
      min-height: 210px;
      margin-top: -1px; } }
  @media (min-width: 992px) {
    .indx .indx-service .info {
      margin-top: 290px;
      padding: 35px 40px 80px 35px;
      min-height: 270px; } }
  .indx .indx-service .info .mtitle {
    line-height: 1;
    letter-spacing: 1.2px;
    margin-bottom: 14px; }
    @media (max-width: 767.99px) {
      .indx .indx-service .info .mtitle {
        font-size: 1.125rem; } }
    @media (min-width: 768px) {
      .indx .indx-service .info .mtitle {
        font-size: 1.5rem; } }
  @media (max-width: 767.99px) {
    .indx .indx-service .info .text-right {
      margin-top: 15px; } }
  .indx .indx-service .info .btn_arrow {
    color: white; }
    .indx .indx-service .info .btn_arrow .svg path {
      fill: #365a75;
      stroke: white; }
    .indx .indx-service .info .btn_arrow .svg line {
      stroke: white; }
    @media (max-width: 767.99px) {
      .indx .indx-service .info .btn_arrow {
        font-size: 0.875rem; }
        .indx .indx-service .info .btn_arrow .svg {
          margin-left: 10px; } }
    @media (min-width: 768px) {
      .indx .indx-service .info .btn_arrow {
        position: absolute;
        right: 40px;
        bottom: 35px; }
        .indx .indx-service .info .btn_arrow:hover path {
          fill: white; }
        .indx .indx-service .info .btn_arrow:hover line {
          stroke: #365a75; } }

@media (max-width: 767.99px) {
  .indx .indx-service .bg1, .indx .indx-service .bg2 {
    display: none; } }

@media (min-width: 768px) {
  .indx .indx-service .bg1 {
    position: absolute;
    top: 250px;
    left: -5%;
    z-index: -1; }
  .indx .indx-service .bg2 {
    position: absolute;
    right: -10%;
    bottom: 500px;
    z-index: -1; } }

@media (max-width: 991.99px) {
  .indx .indx-team {
    margin-bottom: 120px; } }

@media (min-width: 992px) {
  .indx .indx-team {
    margin-bottom: 150px; } }

.indx .indx-team .zindx-1 {
  z-index: 3;
  position: relative; }

@media (max-width: 767.99px) {
  .indx .indx-team .m_article {
    margin-bottom: 30px; } }

@media (min-width: 768px) {
  .indx .indx-team .m_article {
    margin-bottom: 50px; } }

.indx .indx-team .bggray:before {
  content: ' ';
  background-color: #e8e8e8; }
  @media (max-width: 767.99px) {
    .indx .indx-team .bggray:before {
      width: calc(100% - 50px);
      height: calc(100% - 40px);
      position: absolute;
      bottom: -90px;
      left: 25px; } }
  @media (min-width: 768px) {
    .indx .indx-team .bggray:before {
      width: 100%;
      height: calc(100% - 83px);
      position: absolute;
      bottom: -115px;
      left: 0; } }

.indx .indx-team .mflex-3 > div {
  background-color: white; }

@media (max-width: 767.99px) {
  .indx .indx-team .mflex-3 > div {
    margin-bottom: 50px; } }

@media (min-width: 768px) {
  .indx .indx-team .mflex-3 {
    display: flex;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px; }
    .indx .indx-team .mflex-3 > div {
      width: 27%; } }

@media (min-width: 768px) and (max-width: 991.99px) {
  .indx .indx-team .mflex-3 > div {
    width: 30%; } }

.indx .indx-team .fbold {
  font-weight: 700; }

.indx .indx-team .bgcover {
  padding-bottom: 120.37%; }

@media (max-width: 991.99px) {
  .indx .indx-team .mtxt {
    padding: 16px; } }

@media (min-width: 992px) {
  .indx .indx-team .mtxt {
    padding: 16px 24px; } }

.indx .indx-team .mname {
  letter-spacing: 1px;
  font-size: 1.25rem; }

.indx .indx-team .jobtitle {
  margin: 4px 0 12px; }

@media (min-width: 768px) {
  .indx .indx-team .btn_radi {
    margin-top: 30px; } }

.indx .indx-partner .m_article {
  margin-bottom: 60px; }

.indx .indx-partner .img-center img {
  max-height: 100%; }

@media (max-width: 767.99px) {
  .indx .indx-partner .img-center {
    height: 110px; } }

@media (min-width: 768px) {
  .indx .indx-partner .img-center {
    height: 125px; } }

.indx .indx-partner h3 {
  font-size: 20px;
  font-weight: 700; }
  @media (min-width: 768px) {
    .indx .indx-partner h3 {
      margin-top: 20px; } }

.indx .indx-partner .slickprev, .indx .indx-partner .slicknext {
  display: block;
  width: 18px;
  height: 35px;
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }
  .indx .indx-partner .slickprev polyline, .indx .indx-partner .slicknext polyline {
    fill: none;
    stroke: #B9B9B9;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .indx .indx-partner .slickprev:hover polyline, .indx .indx-partner .slicknext:hover polyline {
    stroke: #365a75; }

@media (max-width: 1199.99px) {
  .indx .indx-partner .slickprev {
    left: 15px; } }

@media (min-width: 1200px) {
  .indx .indx-partner .slickprev {
    left: -5%; } }

@media (max-width: 1199.99px) {
  .indx .indx-partner .slicknext {
    right: 15px; } }

@media (min-width: 1200px) {
  .indx .indx-partner .slicknext {
    right: -5%; } }

@media (max-width: 767.99px) {
  .indx .indx-contact {
    margin-top: 60px; } }

@media (min-width: 768px) {
  .indx .indx-contact {
    margin-top: 120px; } }

.indx .indx-contact .bgpink {
  background-color: #f16b66; }

@media (max-width: 991.99px) {
  .indx .indx-contact .mflex-2 .mflex-2 {
    display: flex; }
    .indx .indx-contact .mflex-2 .mflex-2 > div {
      flex: 0  0 50%;
      max-width: 50%;
      position: relative; } }

@media (min-width: 992px) {
  .indx .indx-contact .mflex-2 {
    display: flex; }
    .indx .indx-contact .mflex-2 > div {
      flex: 0  0 50%;
      max-width: 50%; } }

.indx .indx-contact .info {
  color: white; }
  @media (max-width: 767.99px) {
    .indx .indx-contact .info {
      padding: 20px; } }
  @media (min-width: 768px) {
    .indx .indx-contact .info {
      padding: 25px; } }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .indx .indx-contact .info {
      padding: 25px 20px; } }
  .indx .indx-contact .info .m_article h5, .indx .indx-contact .info .m_article h4 {
    color: white; }
  @media (max-width: 991.99px) {
    .indx .indx-contact .info .mflex-2 {
      margin-top: 50px; } }
  @media (min-width: 992px) {
    .indx .indx-contact .info .mflex-2 {
      margin-top: 35px; } }
  .indx .indx-contact .info .mh {
    min-height: 4.5em; }
  .indx .indx-contact .info .bdr {
    border-left: 1px solid white;
    padding-left: 5%; }
    @media (max-width: 767.99px) {
      .indx .indx-contact .info .bdr {
        min-height: 135px; } }
    @media (min-width: 768px) {
      .indx .indx-contact .info .bdr b {
        display: block; } }
  @media (max-width: 767.99px) {
    .indx .indx-contact .info .text-right-sm {
      text-align: right;
      position: absolute;
      right: 0;
      bottom: 0; }
      .indx .indx-contact .info .text-right-sm.pd20 {
        padding-right: 20px; } }
  .indx .indx-contact .info .btn_arrow {
    color: white;
    margin-top: 20px;
    display: inline-block;
    position: relative;
    padding-right: 45px; }
    .indx .indx-contact .info .btn_arrow .svg {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
      .indx .indx-contact .info .btn_arrow .svg path {
        stroke: white;
        fill: #f16b66; }
      .indx .indx-contact .info .btn_arrow .svg line {
        stroke: white; }
    @media (max-width: 767.99px) {
      .indx .indx-contact .info .btn_arrow {
        font-size: 0.75rem;
        text-align: right; }
        .indx .indx-contact .info .btn_arrow b {
          display: block; }
        .indx .indx-contact .info .btn_arrow .lh36 {
          line-height: 36px; } }
    @media (min-width: 768px) {
      .indx .indx-contact .info .btn_arrow:hover .svg path {
        fill: white; }
      .indx .indx-contact .info .btn_arrow:hover .svg line {
        stroke: #f16b66; } }

@media (max-width: 767.99px) {
  .indx .indx-contact .mimg .bgcover {
    padding-bottom: 86%; } }

@media (min-width: 768px) and (max-width: 991.99px) {
  .indx .indx-contact .mimg .bgcover {
    padding-bottom: 55%; } }

@media (min-width: 992px) {
  .indx .indx-contact .mimg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0; }
    .indx .indx-contact .mimg .bgcover {
      height: 100%; } }

.news .btn_arrow {
  font-size: 0.875rem;
  color: #365a75;
  font-weight: 400; }
  .news .btn_arrow .svg {
    stroke: #365a75;
    fill: white; }

@media (min-width: 768px) {
  .news .back:hover .svg path {
    fill: #365a75; }
  .news .back:hover .svg line {
    stroke: white; } }

.news .listHover {
  border-bottom: 1px solid #878686; }
  .news .listHover .btn_arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
  .news .listHover:last-child {
    border: none; }

@media (min-width: 768px) {
  .news .listHover:hover {
    color: #365a75; }
    .news .listHover:hover .btn_arrow .svg path {
      fill: #365a75; }
    .news .listHover:hover .btn_arrow .svg line {
      stroke: white; }
    .news .listHover:hover .mtitle:after {
      background-color: #365a75; } }

.news .single .mdate {
  font-size: 0.875rem;
  color: #878686;
  font-weight: 400; }

.news .single h1 {
  font-size: 1.25rem;
  color: #365a75;
  font-weight: 700;
  margin-bottom: 48px; }

.listHover {
  line-height: 1.2;
  position: relative; }
  @media (max-width: 767.99px) {
    .listHover {
      padding: 16px 0;
      font-size: 1rem;
      color: black;
      font-weight: 400;
      display: block; } }
  @media (min-width: 768px) {
    .listHover {
      display: flex;
      padding: 32px 0;
      font-size: 1.25rem;
      color: black;
      font-weight: 400; } }
  .listHover .mtitle {
    position: relative;
    font-weight: 700; }
    @media (max-width: 767.99px) {
      .listHover .mtitle {
        padding-right: 40px;
        margin-top: 8px; } }
    @media (min-width: 768px) {
      .listHover .mtitle {
        margin-left: 16px;
        padding-left: 20px;
        padding-right: 100px; } }
    @media (min-width: 768px) {
      .listHover .mtitle:after {
        content: ' ';
        width: 1px;
        height: calc(100% - 2px);
        background-color: black;
        position: absolute;
        top: 3px;
        left: 0; } }

.recru .btn_arrow .svg {
  fill: none; }
  .recru .btn_arrow .svg path {
    fill: #f16b66;
    stroke: #f16b66; }
  .recru .btn_arrow .svg line {
    stroke: white; }

@media (min-width: 768px) {
  .recru .btn_arrow:hover .svg path {
    fill: white; }
  .recru .btn_arrow:hover .svg line {
    stroke: #f16b66; } }

@media (max-width: 767.99px) {
  .recru .btn_arrow {
    margin-bottom: 20px;
    display: inline-block;
    font-size: 1rem;
    color: #f16b66;
    font-weight: 400; } }

@media (min-width: 768px) {
  .recru .btn_arrow {
    font-size: 0.875rem;
    color: #f16b66;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0; } }

.recru .arrow {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px; }
  .recru .arrow i {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/h_bnt.png) no-repeat center/20px;
    transition: all 0.6s; }

.recru .active .listHover {
  color: #365a75; }
  .recru .active .listHover .mtitle:after {
    background-color: #365a75; }

.recru .active .arrow i {
  transform: rotate(-180deg); }

.recru .listbox {
  border-bottom: 1px solid #878686; }
  .recru .listbox .editor_Box {
    display: none;
    position: relative; }
  .recru .listbox .editor_inner {
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .recru .listbox .editor_inner {
        padding-right: 120px; } }

.recru .listHover {
  cursor: pointer; }

@media (min-width: 768px) {
  .recru .listHover:hover {
    color: #365a75; }
    .recru .listHover:hover .mtitle:after {
      background-color: #365a75; } }

.contact ul.info {
  line-height: 1.38;
  letter-spacing: 0.8;
  font-size: 1rem;
  color: black;
  font-weight: 400; }
  .contact ul.info a[href^="tel"], .contact ul.info a[href^="tel"]:hover {
    color: black; }
  .contact ul.info li {
    display: flex;
    margin-bottom: 24px; }
  .contact ul.info i {
    display: inline-block;
    width: 25px;
    height: 25px; }
    @media (max-width: 767.99px) {
      .contact ul.info i {
        margin-right: 15px;
        transform: scale(0.85); } }
    @media (min-width: 768px) {
      .contact ul.info i {
        margin-right: 30px; } }
  .contact ul.info i.i_tel {
    background: url(../images/contact/tel.png) no-repeat center/contain;
    vertical-align: -5px; }
  .contact ul.info i.i_fax {
    background: url(../images/contact/fax.png) no-repeat center/contain;
    vertical-align: -5px; }
  .contact ul.info i.i_mail {
    background: url(../images/contact/mail.png) no-repeat center/contain;
    vertical-align: -5px; }
  .contact ul.info i.i_addr {
    background: url(../images/contact/addr.png) no-repeat center/contain;
    vertical-align: -5px; }

.contact .bggray {
  background-color: #f2f2f2; }
  @media (max-width: 767.99px) {
    .contact .bggray {
      padding: 30px 0;
      margin: 70px 0; } }
  @media (min-width: 768px) {
    .contact .bggray {
      padding: 50px 0;
      margin: 90px 0; } }

.contact .des {
  line-height: 1.88;
  margin-bottom: 24px; }
  @media (max-width: 767.99px) {
    .contact .des {
      margin-top: 10px; } }

@media (min-width: 992px) {
  .contact .text-right-lg {
    text-align: right; } }

.fixtxt {
  line-height: 1.88;
  letter-spacing: 0.8px; }
  @media (max-width: 767.99px) {
    .fixtxt .text-justify {
      text-align: initial; } }
  .fixtxt .mtitle {
    letter-spacing: 1.2px;
    line-height: 1;
    margin-bottom: 40px; }
    .fixtxt .mtitle:before {
      content: ' ';
      display: inline-block;
      width: 4px;
      height: 1em;
      background-color: #f16b66;
      margin-right: 14px;
      vertical-align: -3px; }
    @media (max-width: 767.99px) {
      .fixtxt .mtitle {
        font-size: 1.125rem;
        color: #365a75;
        font-weight: 700; } }
    @media (min-width: 768px) {
      .fixtxt .mtitle {
        font-size: 1.5rem;
        color: #365a75;
        font-weight: 700; } }
  @media (max-width: 767.99px) {
    .fixtxt .mh100 {
      height: 70px; } }
  @media (min-width: 768px) {
    .fixtxt .mh100 {
      height: 100px; } }
  .fixtxt h3 {
    line-height: 1;
    text-align: center; }
    @media (max-width: 767.99px) {
      .fixtxt h3 {
        font-size: 1.125rem;
        color: black;
        font-weight: 700; } }
    @media (min-width: 768px) {
      .fixtxt h3 {
        font-size: 1.25rem;
        color: black;
        font-weight: 700; } }
  .fixtxt .img-center {
    justify-content: center;
    align-items: center;
    display: flex; }
  .fixtxt .bggray {
    background-color: #f2f2f2; }

.about h1 {
  display: inline-block;
  line-height: 1.35;
  letter-spacing: 0px;
  border-bottom: 1px solid #365a75; }
  @media (max-width: 767.99px) {
    .about h1 {
      font-size: 1.75rem;
      color: black;
      font-weight: 700; } }
  @media (min-width: 768px) {
    .about h1 {
      font-size: 2.375rem;
      color: black;
      font-weight: 700; } }

.about h1 + div {
  line-height: 1.46;
  letter-spacing: 1.2px; }
  @media (max-width: 767.99px) {
    .about h1 + div {
      font-size: 1.375rem;
      color: black;
      font-weight: 700;
      margin: 30px 0; } }
  @media (min-width: 768px) {
    .about h1 + div {
      font-size: 1.5rem;
      color: black;
      font-weight: 700;
      margin: 30px 0 40px; } }

.about .sign {
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 60px; }
  .about .sign:before {
    content: ' ';
    display: inline-block;
    width: 42px;
    height: 1px;
    margin-right: 10px;
    background-color: black;
    vertical-align: 4px; }
  .about .sign img {
    margin-left: 10px; }
    @media (max-width: 767.99px) {
      .about .sign img {
        width: 85px;
        vertical-align: -22px; } }
    @media (min-width: 768px) {
      .about .sign img {
        width: 102px; } }

@media (min-width: 992px) {
  .about .col-12.pdl0 {
    padding-left: 0; } }

@media (max-width: 767.99px) {
  .about .bggray {
    padding: 12px 20px; }
    .about .bggray b {
      display: block; } }

@media (min-width: 768px) {
  .about .bggray {
    padding: 30px; } }

.about .img-center {
  height: 122px; }

@media (max-width: 767.99px) {
  .about h3 {
    margin: 25px 0 20px; } }

@media (min-width: 768px) {
  .about h3 {
    margin: 36px 0 30px; } }

@media (max-width: 767.99px) {
  .about .mflex-3 > div {
    margin-bottom: 40px; } }

@media (min-width: 768px) {
  .about .mflex-3 {
    display: flex;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px; }
    .about .mflex-3 > div {
      width: 30%;
      padding: 0 15px; } }

.service .anc_point {
  width: 100%;
  height: 1px;
  transform: translateY(-140px); }

.service .imgalign {
  text-align: center; }

@media (max-width: 767.99px) {
  .service .text-justify {
    margin-bottom: 20px; } }

@media (min-width: 768px) {
  .service .text-justify {
    margin-bottom: 40px; } }

@media (min-width: 768px) {
  .service .btn_radi:hover {
    background-color: rgba(0, 0, 0, 0); } }

.service .bggray {
  margin-top: 60px; }
  @media (max-width: 991.99px) {
    .service .bggray {
      padding: 30px 20px; } }
  @media (min-width: 992px) {
    .service .bggray {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px; } }
  .service .bggray .issue {
    text-align: center; }
    @media (max-width: 767.99px) {
      .service .bggray .issue {
        font-size: 1.125rem;
        color: #000;
        font-weight: 400; } }
    @media (min-width: 992px) {
      .service .bggray .issue {
        flex: 0 0 30%;
        max-width: 30%;
        line-height: 1.36;
        letter-spacing: 1.1px;
        font-size: 1.375rem;
        color: #000;
        font-weight: 400; } }
    @media (max-width: 991.99px) {
      .service .bggray .issue > div {
        margin-bottom: 8px; }
        .service .bggray .issue > div img {
          width: 30px; } }
    @media (min-width: 992px) {
      .service .bggray .issue > div {
        margin-bottom: 16px; } }
    @media (min-width: 992px) {
      .service .bggray .issue b {
        display: block; } }
  @media (max-width: 991.99px) {
    .service .bggray ul {
      border-top: 1px solid #dcdddd;
      padding-top: 30px;
      margin-top: 30px; } }
  @media (min-width: 768px) and (max-width: 991.99px) {
    .service .bggray ul {
      width: 80%;
      margin: 30px auto 0; } }
  @media (min-width: 992px) {
    .service .bggray ul {
      flex: 0 0 70%;
      max-width: 70%;
      border-left: 1px solid #dcdddd;
      padding-left: 30px; } }
  .service .bggray ul i {
    letter-spacing: 1.2px;
    line-height: 28px;
    margin-right: 5px; }
    @media (max-width: 991.99px) {
      .service .bggray ul i {
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .service .bggray ul i {
        font-size: 1.5rem; } }
  .service .bggray ul span {
    letter-spacing: 0.8px; }
  .service .bggray ul li:not(.libtn) {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px; }
  @media (max-width: 991.99px) {
    .service .bggray ul li.libtn {
      text-align: center; } }

.service .mflex-4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .service .mflex-4 > div {
    padding: 0 15px; }
    @media (max-width: 991.99px) {
      .service .mflex-4 > div {
        width: 50%; } }
    @media (min-width: 992px) {
      .service .mflex-4 > div {
        width: 25%; } }
  @media (max-width: 767.99px) {
    .service .mflex-4 .img-center {
      width: 50%;
      height: 80px;
      margin: 50px auto 20px; }
      .service .mflex-4 .img-center img {
        max-height: 100%; } }
  @media (min-width: 768px) and (max-width: 991.99px) {
    .service .mflex-4 .img-center {
      margin: 50px 0 30px;
      height: 125px; } }
  @media (min-width: 992px) {
    .service .mflex-4 .img-center {
      margin: 20px 0 30px;
      height: 125px; } }

@media (max-width: 767.99px) {
  .service .sm-mb0 {
    margin-bottom: 0; } }

@media (min-width: 768px) and (max-width: 991.99px) {
  .service .show-sm {
    display: none; }
  .service .show-lg {
    display: block; } }

@media (max-width: 991.99px) {
  .service .sm-mb50 {
    margin-bottom: 50px; } }

@media (min-width: 768px) {
  .service .lg-mt70 {
    margin-top: 70px; } }

.terms header .svg {
  width: 23px;
  height: 23px; }
  @media (max-width: 991.99px) {
    .terms header .svg {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); } }

.terms header path {
  fill: #365a75;
  transition: all 0.6s; }

.terms header .bar {
  display: none; }

.terms header .menu-expanded.menuicon .bar {
  display: block; }

.terms header .menu-expanded.menuicon .svg {
  display: none; }

@media (max-width: 991.99px) {
  .terms header .navBox {
    padding-top: 20px; } }

.terms h5 {
  font-family: 'Roboto', 'Microsoft JhengHei', "微軟正黑體", sans-serif; }
  @media (max-width: 767.99px) {
    .terms h5 {
      font-size: 1.125rem;
      color: #365a75;
      font-weight: 700;
      margin: 50px 0 20px; } }
  @media (min-width: 768px) {
    .terms h5 {
      font-size: 1.5rem;
      color: #365a75;
      font-weight: 700;
      margin: 90px 0 40px; } }

.terms .editor_Box {
  margin-bottom: 50px; }

@media (max-width: 767.99px) {
  .terms .btn_radi {
    margin: 0 10px;
    min-width: 130px; } }

@media (min-width: 768px) {
  .terms .btn_radi {
    margin: 0 15px; } }
