//語系
.lang_tw{
    .en,.cn{
        display: none;
    }
}
.lang_cn{
    font-family:"Microsoft Yahei";
    .tw,.en{
        display: none;
    }
}
.lang_en{    
    .tw,.cn{
        display: none;
    }
    letter-spacing: 0;
    .text-justify{
        text-align: left;
    }
    .indx .indx-contact .info .mh{
        font-size: pxToEm(15);
    }
    .service .bggray ul span{
        letter-spacing: 0;
    }
    .en{
        @extend .fen;
        letter-spacing: 0;
    }
}