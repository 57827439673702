//overwrite
body{    
    font-family: $mfont;    
    @include font-color(pxToEm(16),#000);
    line-height: 1.5;
    letter-spacing: 0.8px;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    &.fade-in{
        opacity: 1;
        transition:opacity  1s ease;
        visibility: visible;
    }
}
main{   
    @include media-breakpoint-down(xs){
        .container,.col-12{
            padding-left: 25px;
            padding-right: 25px;
        }
        .row{
            margin-right: -25px;
            margin-left: -25px;
        }
    }
    @include media-breakpoint-down(sm){
        padding-bottom: 50px;
        padding-top: 72px;
    } 
    @include media-breakpoint-up(md){  
        padding-bottom: 130px;
        padding-top: 76px;
        .container{
            max-width: 1200px;
        }
    }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .row .col-lg-10.offset-lg-1:not(.offset-not){
            margin: 0 auto;
            flex: 0 0 98%;
            max-width: 98%;
        }
    }
}
.m_article{
    line-height:1;
    h5{
        color:$blue;
        @extend .fen;
        @include media-breakpoint-down(sm){
            font-size: pxToEm(32);
            margin-bottom: 6px;
        }
        @include media-breakpoint-up(md){
            font-size: pxToEm(45);
            margin-bottom: 12px;
        }
    }
    h4{
        color:$blue;
        font-weight: 700;
        letter-spacing: 1.3px;
        @include media-breakpoint-down(sm){
            font-size: pxToEm(20);
        }
        @include media-breakpoint-up(md){
            font-size: pxToEm(26);
        }
    }
}
//page banner
.pgname{
    position: relative;
    @include media-breakpoint-down(sm){
        margin-bottom: 60px;
        height: 225px;
        .show-l{
            display: none;
        }
    }
    @include media-breakpoint-up(md){
        margin-bottom: 70px;
        height: 370px;
        .show-m{
            display: none;
        }
    }
    @include media-breakpoint-up(lg){
    }
    .bgcover{
        height: 100%;
    }
    .m_article{
        width:100%;
        max-width: $maxConentW;
        @include position(absolute,$top:50%,$left:50%);
        transform: translate(-50%,-50%);
        @include media-breakpoint-down(xs){
            padding:0 25px;
        }
        @include media-breakpoint-up(sm){
            padding:0 $maxConentPding;
        }
        @include media-breakpoint-up(lg){
            top:calc(50% + 38px);
            h5,h4{
                margin-left: 8.33333%;
            }
        }
    }
    .imgalpha{
        @include position(absolute,$bottom:0,$left:0);
        width:100%;
        height: 100%;
        background: url(../images/page/imgalpah.png) no-repeat;
        background-size: contain;
        @include media-breakpoint-down(sm){
            background-position: -50% 100%;
        }
        @include media-breakpoint-up(md){
            background-position:  left bottom ;
        }
    }
    img{
        width:100%;
    }
}
.svg{
    path,line{
        transition: all 0.6s;
    }
}
.btn_arrow{
    letter-spacing: 0.7px;
    .svg{
        stroke-width:2;
        stroke-linecap:round;
        stroke-miterlimit:10;
        width:32px;
        height: 32px;
        vertical-align: -11px;
        margin-left: 12px;
    }
    @include media-breakpoint-down(sm){
        i{
            display: none;
        }
    }
}
.btn_radi{
    display: inline-block;
    @include border();
    background-color: $pink;
    border:1px solid $pink;
    text-align: center;
    &.mgray,&.indxbtn{
        background-color: #b9b9b9;
        border:1px solid #b9b9b9;
    }
    @include media-breakpoint-down(sm){
        padding:0 35px;
        min-width: 150px;
        line-height: 43px;
        height: 43px;
        @include font-color(pxToEm(18),white);
        &:hover{
            color:white;
        }
    }
    @include media-breakpoint-up(md){
        padding:0 28px;
        min-width: 100px;
        line-height: 33px;
        height: 33px;
        @include font-color(pxToEm(14),white);
        &:hover{
            background-color: white;
            color:$pink;
        }
        &.mgray:hover{
            background-color: white;
            color:#b9b9b9;
        }
        &.indxbtn:hover{
            background-color: $pink;
            border-color:$pink;
            color:white;
        }
    }
}
.editor_Box{
    //team
    &.mb80{
        margin-bottom: 80px;
    }
}
div[data-timeout]{
    opacity: 0;
    transform: translateY(50px);
}
div.animated[data-timeout]{
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 1s ease-in ,transform 1s;
}