/*----------------------通用設定------------------------*/
//清除float浮動
%cf,main[role^=main]  {
    zoom: 1;
    &:before,
    &:after {
        content: "";
        display: table;
        height: 0;
        overflow: hidden;
    }
    &:after {
        clear: both;
    }
}
.clearfix {
  @include clearfix();
}

//selection
p,h1,h2,h3,h4,b,strong,span,li,div,a,img{
    &::selection{
          color:#fff;
          background:#000;
        }
}
//a tag
a{outline: none;}
a,a:hover {
  text-decoration: none;
  transition: 0.4s;
  color:initial;
}
//phone
a[href^=tel] {
  color:$fontcolor;
  cursor: default;
  &:hover{
    color:$fontcolor;
  }
}
*:focus {
    outline: none;
}
img {
    vertical-align: middle;
    max-width: 100%;
}
ul{
  list-style-type: none;
}
//border-box
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*----------------------編輯器------------------------*/
.editor_inner {
  margin: 0 auto;
  @include font-color(pxToEm(16),black);
  line-height: 1.88;
  letter-spacing: 0.8px;
  ul,ol {
      padding-left: 40px;
  }
  img {
      max-width: 100%;
      height: auto !important;
  }
  iframe {
      max-width: 100%;
      @media screen and (max-width: 767px) {
          height: auto !important;
      }
  }
  h1,h2,h3,h4,h5,h6 {
      margin: inherit;
  }
}
/*----------------------頁籤------------------------*/
.page{
    margin:60px 0 0px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    dt a{
      width:8px;
    }
    a{
      @include font-color(pxToEm(20),#878686)
      display: block;
      line-height: 1;
    }
    dd.active a{
      color:$blue;
    }
    .svg{
      width:8px;
      height: 14px;
      fill:none;
      stroke:#878686;
      stroke-width:1.5;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
    @include media-breakpoint-down(sm){
      justify-content: space-around;
    }
    @include media-breakpoint-up(md){
      justify-content: center;
      a{
        margin:0 15px;
      }
      a:hover{
        color:$blue;
        svg{
          stroke:$blue;
        }
      }      
      .ltbn{
        margin-right: 10px;
      }
      .rtbn{
        margin-left: 10px;
      }
    }
    .nopage{
      opacity: 0;
      z-index: -1;
    }
    
}
/*----------------------go top------------------------*/
#topBtn{
  width:50px;
  height:36px;
  text-align: center;
  display:block;
  z-index:1;
  opacity: 0;
  visibility: hidden;
  // background: rgba(#818181,1);
  @include font-color(pxToEm(12),$pink,500);
  // transition:all 0.6s;
  img{
    width:35px;
    transition: all 0.6s;
    transform: translateY(-3px);
  }
  &.open{
    opacity: 1;
    visibility: visible;
    z-index:100;
  }
  &.change{
    position: absolute;
    @include media-breakpoint-down(xs){
      bottom:10px;
    }
    @include media-breakpoint-up(sm){
      bottom:25px;
    }
  }
  @include media-breakpoint-down(xs){
    @include position(fixed,$bottom:80px,$right:10px);
  }
  @include media-breakpoint-up(sm){
    @include position(fixed,$bottom:25px,$right:30px);
  }
  @include media-breakpoint-up(md){
    &:hover{
      img{
        transform: translateY(-10px);
      }
    }
  } 
}
.recru,.contact{
  #topBtn{
    &.change{
      position: absolute;
      @include media-breakpoint-up(sm){
        bottom:80px;
      }
    }
    @include media-breakpoint-up(sm){
      @include position(fixed,$bottom:80px,$right:30px);
    }
  }
}
/*----------------------top-menu-ul------------------------*/
.rowbox.top-menu-row{
  @include media-breakpoint-down(md){
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}
#top-menu-ul{
  
  position: relative;
  z-index: 1;
  @include media-breakpoint-down(sm){
    margin:0 auto 30px;
  }  
  @include media-breakpoint-up(md){
    margin-bottom: 60px;
  }
  .item_Menu{
      width:calc(100% - 40px);
     margin: 0 auto;   
     overflow: hidden;    
  }
  .item_menu_Box{    
      width:100%;
      overflow:hidden;
      overflow-x: scroll;
      position: relative;
      text-align:center;
      // @include media-breakpoint-down(xs){
      //   height: 40px;
      // }
      // @include media-breakpoint-up(sm){
      //   height: 50px;
      // }
      .item_menu_list{
          white-space: nowrap !important;
          // width: auto;
          padding-bottom: 0px;
          text-align: center;
          font-size:0px;
          display:inline-flex; 
          // @media screen and ( max-width: 441px ) {
          //     overflow-y: hidden;
          //     overflow-x: scroll;
          //     width:100%;          
          // }
      }

      li{
          // // display: inline-block;   
          // font-size: 0; 
          @include media-breakpoint-down(xs){
            height: 40px;
          }
          @include media-breakpoint-up(sm){
            height: 50px;
          }
      }
      li a{
          position: relative;
          background-color: rgba(#ddd,1);
          text-align: center;
          display: block;
          margin:0 10px; 
          letter-spacing: 0.9px;          
          @include border(25px); 
          @include media-breakpoint-down(xs){
            @include font-color(pxToEm(16),black);
            height: 40px;
            line-height: 40px;
            padding:0 20px;
          }
          @include media-breakpoint-up(sm){
            @include font-color(pxToEm(18),black);
            height: 50px;
            line-height: 50px;
            padding:0 30px;
          }
      }
      li.active a{
          color: white;
          background-color: rgba($orange,1);
      }
      @include media-breakpoint-up(md){
          li a:hover{
              color: white;
              background-color: rgba($orange,1);
          }
      }
  }
}
.flex-direction-nav{
  display:none;
  a{
      display:block;
      width:30px;   
      position: absolute;        
      transform: translateY(-50%);
      top:50%;
      z-index: 10;
      &:after{
          @include position(absolute,$top:0%);
      }
      @include media-breakpoint-down(xs){
        height:40px;  
      }
      @include media-breakpoint-up(sm){
        height:50px;  
      }
  }
  a.flex-next{
      background: url(../svg/slick-next.svg) white no-repeat center / 15px;
    
      @include media-breakpoint-down(xs){
        right: -20px;
      }
      @include media-breakpoint-up(sm){
        right: 0px;
      }
  }

  a.flex-prev{
      background: url(../svg/slick-prev.svg) white no-repeat center / 15px;
     
      @include media-breakpoint-down(xs){
        left:  -20px;
      }
      @include media-breakpoint-up(sm){
        left: 0px;
      }
  }
  
  @media screen and ( max-width: 441px ) {
      display: block;
  }
}
.open_flexslider{
  .flex-direction-nav{
      display: block;
  }
}
// custom
.text-center{text-align: center;}
.text-right{text-align:right;}
.text-justify{text-align: justify;}
.mpsrel{position: relative;}
.hide{display:none;}
.bgcover{
  width:100%;
  height:0;
  background: center / cover;
}
.rowbox{
  margin:0 auto;
  position: relative;
  max-width:$maxConentW;
  padding:0 $maxConentPding;
}

@include media-breakpoint-down(md){
  &.show-lg{
    display: none;
  }
  .row.col-md-center{
    justify-content: center;
  }
  .bgcover[data-src-lg]{
    content: attr(data-src-lg, url);
  }
}
@include media-breakpoint-up(lg){
  &.show-sm{
    display: none;
  }
  .bgcover[data-src-sm]{
    content: attr(data-src-sm, url);
  }
}