footer{    
    background-color:$blue;
    line-height: 1.86;
    letter-spacing: 0.7px;
    @include font-color(pxToEm(14),white);
    @include media-breakpoint-down(sm){
        padding:25px 0px 15px;
    }
    @include media-breakpoint-up(md){
        padding:40px 15px;
    }
    .row{
        max-width: 1230px;
        margin:0 auto;
    }
    .flogo{        
        img{            
            image-rendering: -moz-crisp-edges;  
            image-rendering: -o-crisp-edges;     
            image-rendering: -webkit-optimize-contrast; 
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor; 
        }
        @include media-breakpoint-down(sm){
            width:150px;
        }
        @include media-breakpoint-up(md){
            width:180px;
        }
    }
    .copyright{
        @include media-breakpoint-down(sm){
            margin-top: 20px;
        }
        @include media-breakpoint-up(md){
            @include position(absolute,$right:15px,$bottom:0px);
        }
        color:#94a5b2;
    }
    a[href^="tel"], a[href^="tel"]:hover,
    a,a:hover{
        color:white;
    }
    .info{
        margin-top: 24px;
        @include media-breakpoint-up(md){
            display: inline-flex;
            flex-wrap: wrap;
            li:not(:first-child){
                margin-left: 20px;
                padding-left: 20px;
                position: relative;
                &:before{
                    content:' ';
                    display: inline-block;
                    width:1px;
                    height: 1em;
                    background-color: white;
                    @include position(absolute,$left:0,$top:50%);
                    transform: translateY(-50%);
                }
            }
        }
        .w100{
            flex:0 0 100%;
        }
    }
}