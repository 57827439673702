.news{
    .btn_arrow{
        @include font-color(pxToEm(14),$blue);        
        .svg{
            stroke: $blue;
            fill:white;
        }
    }
    .back:hover{
        @include media-breakpoint-up(md){
            .svg{
                path{
                    fill:$blue;
                }
                line{
                    stroke:white;
                }
            }
        }
    }
    .listHover{
        border-bottom: 1px solid #878686;
        .btn_arrow{
            @include position(absolute,$right:0,$top:50%);
            transform: translateY(-50%);
        }
        &:last-child{
            border:none;
        } 
    }     
    .listHover:hover{
        @include media-breakpoint-up(md){  
            color:$blue;
            .btn_arrow .svg{
                path{
                    fill:$blue;
                }
                line{
                    stroke:white;
                }
            }
            .mtitle:after{
                background-color: $blue;
            }
        }
    }
    .single{
        .mdate{
            @include font-color(pxToEm(14),#878686);
        }
        h1{
            @include font-color(pxToEm(20),$blue,700);
            margin-bottom: 48px;
        }
    }
}
//common new / recruitment
.listHover{
    line-height: 1.2;
    position: relative;
    @include media-breakpoint-down(sm){
        padding:16px 0;
        @include font-color(pxToEm(16),black);
        display: block;
    }
    @include media-breakpoint-up(md){
        display: flex;
        padding:32px 0;
        @include font-color(pxToEm(20),black);
    } 
    .mtitle{
        position: relative;
        font-weight: 700;
        @include media-breakpoint-down(sm){
            padding-right: 40px;
            margin-top: 8px;
        }
        @include media-breakpoint-up(md){
            margin-left: 16px;
            padding-left: 20px;
            padding-right: 100px;
        }
        &:after{
            @include media-breakpoint-up(md){
                content:' ';
                width:1px;
                height: calc(100% - 2px);
                background-color: black;
                @include position(absolute,$left:0,$top:3px);
            }
        }
    }
}