.form_des{
   text-align: center;
   line-height: 1.88;
   @include media-breakpoint-down(sm){
        margin-bottom: 20px;
       b{
           display: block;
       }
    }
    @include media-breakpoint-up(md){
        margin-bottom: 40px;
    }
}
form{
    overflow: hidden; 
    letter-spacing: 1.2px;
    input,select,textarea{
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        box-shadow:none;
        width:100%;
        padding:0px 0px 5px;
        background-color:rgba(#f2f2f2,0);
        border: none;
        border-bottom: solid 1px rgba(#878686,0.5);
        @include border(0);
        @include font-color(pxToEm(16),black);
        &:focus { outline: none; }
    }
    input{
        @include placeholder {
            @include font-color(pxToEm(16),#878686);
        }
    }
    input[type="radio"]{
        @include border(0); 
        padding:0;
    }
    label{
        line-height: 1;
        letter-spacing: 0.9px;
        // @include font-color(pxToEm(16), black); 
        margin-bottom: 10px;
    }
    label > i{
        line-height: 0.5em;
        vertical-align: 6px;
        margin-left: -7px;
        @include font-color(pxToEm(16),$pink);
    }
    &.form-horizontal .form-group{
        display: flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .form-group{
        // margin-left:-15px;
        // margin-right: -15px;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: center;
        // align-items: center;
        margin-bottom: 0;
        &.mtop{
            align-items: flex-start;
        }
        &.mcenter{
            align-items: center;
        }
        @include media-breakpoint-only(md){
            margin-bottom: 40px;
        }
    }
    .with-errors{
        flex:0 0 100%;
        min-height:14px;
        line-height:14px;
        letter-spacing: 0.7;
        display:block;
        @include media-breakpoint-down(sm){
            @include font-color(pxToEm(16),$pink);  
            margin:7px 0 10px;
        }
        @include media-breakpoint-up(md){
            @include font-color(pxToEm(14),$pink);  
            margin:3px 0 5px;
        }
    }
    button[type="submit"]{
        outline: none;
        background-color: rgba(white,0);
        // padding:0 0 5px;
        border:none;
        cursor: pointer;  
        // @include media-breakpoint-up(md){
        //     .btn_more{
        //         width:200px;
        //         height: 60px;
        //         line-height: 60px;
        //         transition: all 0.6s;
        //         @include border(30px);
        //         &:after,&:before{
        //             @include border(30px);
        //         }
        //     }
        // }
    }
    @include media-breakpoint-up(lg){   
        textarea{
            // height: 296px;
        }   
        .text-r label{
            text-align: right;
        }
    }
    @include media-breakpoint-up(md){
        input,select{
            height:30px;
        }
        input,textarea,select{            
            // @include border(15px); 
        }
    }
    @include media-breakpoint-down(sm){
        input,select{
            height: 30px;
        }
        input,textarea,select{            
            // @include border(8px); 
        }
    }
    //radio & check
    .flex-check_radi{        
        input{
            height: 30px;
        }
        @include media-breakpoint-down(xs){  
            @include font-color(pxToEm(16),black); 
            label{
                line-height: 30px;
                margin-bottom: 5px;
            }
            .check_radi{
                line-height: 30px;                
                padding: 0 0px 0 30px;
                margin: 0 15px 5px 0px;
            }
        }
        @include media-breakpoint-up(sm){   
            @include font-color(pxToEm(18),black); 
            label{
                line-height: 30px;
                flex:0 0 120px;
                width:120px;
            }
            .g_check_radi{
                display: inline-flex;
                flex-wrap: wrap;
                flex:0 0 calc(100% - 130px);
                width:calc(100% - 130px);
            }  
            .check_radi{
                line-height: 30px;
                padding: 0 0px 0 50px;
                margin: 0 35px 20px 0px;
            }
        }
    }
    .check_radi {
        width: auto;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            left: 0;
            top:0;
            z-index: 5;
        }
        .boxmark {
            @include position(absolute,$top:50%,$left:0);
            height: 28px;
            width: 28px;
            // background-color: #cacaca;
            border:1px solid #dcdcdc;
            @include border();
            @include media-breakpoint-down(xs){
                transform: translateY(-50%) scale(0.7);
                transform-origin: left;
            }
            @include media-breakpoint-up(sm){
                transform: translateY(-50%);
            }
        }
        .boxmark:after {
            content: "";
            @include position(absolute,$top:6px,$left:6px);
            display: none;
            width: 14px;
            height: 14px;
            background: $orange;
            @include border();
        }
        input:checked~.boxmark:after {
            display: block;
        }
        .checkmark {
            @include position(absolute,$top:50%,$left:0);
            height: 30px;
            width: 30px;
            border:1px solid #d2d2d2;
            transform: translateY(-50%);
        }
        .checkmark:after {
            content: "";
            @include position(absolute,$top:6px,$left:4px);
            display: none;
            width: 22px;
            height: 16px;
            background: url('../images/agree_yes.png') no-repeat center / contain ;
        }
        input:checked~.checkmark:after {
            display: block;
        }
    }
    //select
    select::-ms-expand { display: none; }    
    select{
        // background:url('../images/select_down.png') no-repeat #313131 calc(100% - 15px) center / 10px;
        padding:3px 15px 3px 10px ;
        outline: none;
    }
    @media screen\0 {
        select{
        // background:url("../images/select_down.png") no-repeat #313131  calc(100% - 15px) center / 10px;
        }
    }
}

//other form
form{
    .textarea-wrapper{
        height: 194px;
        overflow: hidden;
        border-bottom: solid 1px rgba(#878686,0.5);
        padding:0px 0px 10px;
        textarea{
            min-height: 174px;
            overflow: hidden;
            border-bottom:none;
            padding:0;
            text-rendering: auto;
            -webkit-writing-mode: horizontal-tb !important;
            cursor:text;
        }
    }
    .agree{
        margin-top: 10px;
        margin-bottom: 12px;
        .check_radi{
            padding-left: 40px;
            min-height: 30px;
        }
        .with-errors{
            margin-top: 5px;
        }
        .with-errors.hide{
            display: none;
        }
    }
    .editor_Box.recu{
        height: 200px;
        border-bottom:1px solid rgba(#878686,0.5);
        padding-bottom: 20px;
        margin-top: 12px;
    }
    .mflex-upload{
        display: flex;
        align-items: center;
        //file-upload
        label[for="file-upload"]{
            cursor:pointer;
            margin-bottom: 0;
            margin-right: 12px;
        }
        .file_name{
            color:#878686;
        }
        #file-upload {
            position: absolute;
            left: -9999px;
        }
    }
    .fpink{
        color:$pink;
        font-size: pxToEm(14);
    }
    .fgray{
        color:#878686;
    }
    .submit_recu{
        margin-top: 60px;
    }
    .submit_contact{
        margin-top: 15px;
    }
    .btn_radi:hover{
        @include media-breakpoint-up(md){
            background-color: rgba(0,0,0,0);
        }
    }
}
//overwrite plugin scroll
.mCSB_scrollTools{
    opacity: 1;
}
.mCSB_scrollTools .mCSB_draggerRail{
    background-color: #bfbebe;
    width:1px;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    width:9px;
    background-color: #cecece !important;
    opacity: 1 !important;
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
    background-color: #dcdcdc;
}
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar{
    opacity: 1 !important;
}
